.top-bar {
  display: flex;
  justify-content: space-between;
  .search-box {
    input {
      background: white;
      border: none;
      width: 354px;
      height: 40px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border: 1px solid white !important;
      border-left: none;

      /* Search */

      font-family: "Ageo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #7d8592;

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }

    span#basic-addon1 {
      background: white;
      border: 1px solid white;
      border-right: none;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding-left: 15px;
      cursor: pointer;
      :hover {
        path {
          fill: $base-yellow;
        }
      }
    }
  }

  .notification-box {
    background: white;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    &:hover{
      background-color: $hover-bg;
      path{
        fill: $black;
      }
    }
  }

  .profile-dropdown {
    width: 162px;
    height: 40px;
    border-radius: 10px;
    background: white;
    cursor: pointer;

    &:hover{
      background: $hover-bg;
    }

    &:has(:focus){
      background: $base-yellow;
    }

    .profile-title {
      /* John Smith */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
    .manage-profile {
      /* Manage Profile */
      font-family: "Ageo";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      color: #707581;
    }
  }
}


@media (max-width:576px){
  .top-bar{
    .search-box{
      input{
        width: 200px;
      }
    }
  }
}