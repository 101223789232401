.navbar-bg {
  background-color: $navbar-bg;
  padding-top: 24px;

  a.navbar-brand {
    margin-bottom: 20px;
  }

  .divider {
    hr {
      /* Separator */
      height: 1px;
      background: #e3e3f1;
      border-radius: 8px;

      margin-top: 0;
    }
  }

  .search-bar {
    input.form-control {
      border-radius: 14px;
      border: none;
      border: 1px solid #e3e3f1;
      border-right: none;
      font-family: "Ageo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #8888a2;
      padding: 14px;
      max-width: 100%;
      height: 48px;
      background: white;
      border-radius: 14px;
    }

    span#basic-addon2 {
      background: wheat;
      background: white;
      border: 1px solid #e3e3f1;
      border-left: none;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }

  .signin-btn {
    background: $primary-btn-bg;
    width: 79px;
    height: 48px;
    font-family: "Ageo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $btn-text;
    border: none;
    border-radius: 20px;
    &:focus,
    &:hover {
      background: $accent-black;
      color: $base-yellow;
      transition: 0.3s;
    }
  }

  .heart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 48px;
    height: 48px;
    background: $white-color;
    box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
    border-radius: 24px;
  }

  .header-bottombar {
    margin-top: 24px;
    padding-bottom: 14px;
    .category-btn {
      border-radius: 100px;
      width: 129px;
      height: 48px;
      background: $white-color;
      border: 1px solid $white-color;
      font-family: "Ageo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #020203;
      margin-right: 16px;
    }

    .menu-options {
      ul {
        list-style: none;
        text-decoration: none;
        display: flex;
        margin-top: 20px;

        li {
          margin-right: 17px;
          /* Health & Beauty */
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $black;
          padding-bottom: 10px;
        }
      }
    }
  }
}
