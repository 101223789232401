$base-yellow: #dbbb51;
$base-gray: #3a3b3b;
$black: #000000;
$light-gray: #5c5c5c;
$bg-gray: #e3e3f1;
$footer-color: #fff1c7;
$navbar-bg: #f6f6fa;
$primary-fonts: "Ageo", sans-serif;
$primary-btn-bg: #dbbb51;
$btn-text: #020203;
$accent-black: #1e1d1c;
$white-color: #fdfdff;
$footer-yellow: #fff1c7;
$footer-gray: #f6f6fa;
$colorgray: #707581;
$light-grey: #77778d;

// Buyer Role Variables
$border-gray: #e3e3f1;
$banner-btn: #fdfdff;
$size-color: #7c7c7e;
$stock-btn-bg: #d7fae9;
$stock-btn-text: #069952;
$tab-btn-active: #fff1c7;
$gray-line: #ceced4;
$carts-title: #363636;
$cart-sub-price: #707581;
$delete-txt: #f76356;
$hover-bg: #F7F4EB;

// Buyer Role Variables End

.mt_10 {
  margin-top: 10px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_24 {
  margin-top: 24px;
}

.mt_30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_60 {
  margin-top: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_40 {
  margin-bottom: 40px;
}

.ms_16 {
  margin-left: 16px;
}

.ms_50 {
  margin-left: 50px;
}

.main-section {
  .container {
    max-width: 1200px;
  }
}

.LineGraph {
  button:not(:disabled) {
    cursor: pointer;
    background: #f6f8fa;
    border: none;
    text-align: right;
    height: 48px;
    padding: 0 10px;
    border-radius: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #363636;
  }
}

.patient-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px;
  height: 414px;

  .patients {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    color: #363636;
    padding: 20px 0 0 30px;
  }
  canvas {
    height: 60% !important;
    width: 80% !important;
    margin: auto;
  }
}

.donut-chart-container {
  background: #ffffff;
  border-radius: 14px;
  height: 414px;
  padding: 20px;
  padding-top:30px;
  

  canvas {
    height: 100% !important;
    width: 230px !important;
    margin: auto;
    margin-top: 50px;
  }
}

.containers-heading {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: black;
}

.dropdown-custom-btn {
  text-align: right;

  select.form-select {
    // background-image: url("/src/assets/images/Dashboard/dropdown.png");
    // background: white;
    border: 1px solid #707581;
    border-radius: 20px;
    color: #707581;
    height: 40px;
    min-width: 120px;
    box-shadow: none;
  }

  button#dropdown-basic {
    background: white;
    border: 1px solid #707581;
    border-radius: 20px;
    color: #707581;
    height: 40px;
    min-width: 120px;
    text-overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // .dropdown-toggle::after{
    //     content:"";
    //     background-image: url("/src/assets/images/Dashboard/dropdown.png");
    //     background-size: cover;
    //     border: none!important;
    // }

    /* Button */
    font-family: "Ageo";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    color: #707581;
  }

  .dropdown-menu.show {
    height: 140px;
    overflow: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

.table-container {
  background: #ffffff;
  border-radius: 14px;
  height: 100%;
  margin-top: 30px;
}

.order-table-container {
  min-height: 637px;
  background: #ffffff;
  border-radius: 14px;
  padding: 24px 20px;
}

.datagrid-table {
  border: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator,
.css-ptiqhd-MuiSvgIcon-root {
  display: block !important;
}

// .MuiDataGrid-iconButtonContainer.css-ltf0zy-MuiDataGrid-iconButtonContainer{

// }

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: inherit;
  display: none !important;
}

.css-1e2bxag-MuiDataGrid-root
  .MuiDataGrid-columnHeader:hover
  .MuiDataGrid-menuIcon {
  display: none !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #a1a0a0;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
}

.product-table-title {
  /* PN0001265 */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #363636;
  margin-left: 10px;
}

.order-table {
  .MuiDataGrid-columnHeaders.css-f3jnds-MuiDataGrid-columnHeaders {
    padding-left: 20px!important;
  }

  .MuiDataGrid-virtualScrollerContent.css-1kwdphh-MuiDataGrid-virtualScrollerContent {
    padding-left: 20px!important;
  }
  .MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter {
    display: none!important;
  }

  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    display: none!important;
  }

  .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeaderCheckbox {
    display: none!important;
  }

  .css-iclwpf .MuiDataGrid-menuIcon {
    display: none!important;
}


.css-iclwpf .MuiDataGrid-columnSeparator {
    display: none!important;
}


.MuiDataGrid-columnHeaders.css-1nitb7c {
    padding-left: 20px!important;
}


.MuiDataGrid-virtualScrollerRenderZone.css-1inm7gi {
    padding-left: 20px!important;
}
}

.dispatched {
  width: 100px;
  height: 26px;
  background-color: #fff8ed;
  border-radius: 20px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #ff9900;
}

.instock {
  background: #ecfff6;
  width: 100px;
  height: 26px;
  border-radius: 20px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #069952;
}

.outstock {
  background: #fff8ed;
  width: 100px;
  height: 26px;
  border-radius: 20px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff9900;
}

.inprocess {
  width: 100px;
  height: 26px;
  background-color: #edf9ff;
  border-radius: 20px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #09acfe;
}

.completed {
  width: 100px;
  height: 26px;
  background-color: #ecfff6;
  border-radius: 20px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #069952;
}

.order-table-section {
  background: white;
  border-radius: 14px;
  padding-top: 30px;
  margin-top: 30px;

  .order-title {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-left: 20px;
  }
}

.view-all-btn {
  width: 108px;
  height: 40px;
  background: white;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #707581;
  margin-right: 20px;
  border: none;
  box-shadow: none;

  &:focus,
  &:hover {
    background: white !important;
    color: #707581 !important;
  }
}

.main-title-heading {
  margin-top: 30px;
  margin-bottom: 30px;
  /* Dashboard */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #121212;
}

.main-subheading {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #121212;
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.user-button {
  button#dropdown-basic-button {
    width: 30px;
    height: 30px;
    background: #fafafb;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:focus {
      border: none;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.vertical-btn {
  button#dropdown-basic-button {
    background: transparent;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:focus {
      border: none;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}


.css-1e2bxag-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  overflow: visible !important;
  white-space: nowrap;
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: rgba(219, 187, 81, 0.08) !important;
}

.dropdown-menu.show {
  padding: 15px;
  /* Dashboard */
  padding-left: 0;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707581;
  border: 1px solid $base-yellow;
  min-width: 112px;
}

.nav-multidropdown {
  position: relative;

  .test {
    top: 20px;
    display: none;
    position: absolute;
    background: $white-color;
    height: 628px;
    width: 322px;
    z-index: 999;
    padding-top: 15px;
    .new-test {
      padding: 8px 15px;
      .new-test-result {
        display: none;
        position: absolute;
        background: $white-color;
        width: 400px;
        height: 628px;
        left: 320px;
        top: 0;
        border-left: 1px solid $border-gray;
        padding-top: 15px;
        padding-left: 30px;
      }
      &:hover,&:focus {
        background: $navbar-bg;
        .new-test-result {
          display: block;
        }
      }
    }
  }
  &:hover,&:focus {
    .test {
      display: block;
    }
  }
}

.dropdown-main-heading {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $black;
  padding-left: 15px;
  margin-bottom: 8px;
}

.dropdown-all-home{
  margin-top: 16px;
  .dropdown-all-home-text{
    font-family: $primary-fonts;
    color: $black;
    font-size: 16px;
    font-weight: 600;
    padding-right: 60px;
  }
}

.dropdown-menu-option {
  /* Home */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;
}

.second-col-box {
  border-left: 1px solid $border-gray;
  padding-left: 30px;
}
.mb_24{
  margin-bottom: 24px;
}

.mt_16{
  margin-top: 16px;
}

.ml_7{
  margin-left: 7px;
}
.dropdown-menu-sub-option {
  /* Wall Decor */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  margin-top: 16px;
  a{
    color: $black;
  }
}


.buyer-navbar-profile{
  background: $white-color;
  padding: 4px;
  width: 76px!important;
  height: 48px;
  border-radius: 100px;
  padding-left: 10px;
  cursor: pointer;
  box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);

  .img-box{
    width: 40px;
    height: 40px;
    border-radius: 24px;
  }
}



.iconfeild {
  position: absolute;
  bottom: 10px;
  right: 13px;
  background-color: #f6f6fa;
  width: 30px;
  text-align: end;
}

.toggle-password {
  cursor: pointer;
}

.position-realtive{
  position: relative;
}

.confirmed{
  color: #09ACFE !important;
}
.pending{
  color: #DBBB51 !important;
}
.completedd{
  color: #069952 !important;

}
.cancelled{
  color: #C6000F !important;
}


.text-red {
  color: red;
  position: absolute;
  top: 44px;
  font-size: 11px;
  left: 15px;
}






.css-iclwpf .MuiDataGrid-row.Mui-selected{
  background-color: rgba(219, 187, 81, 0.08) !important;
}


.css-iclwpf .MuiDataGrid-columnHeader:focus, .css-iclwpf .MuiDataGrid-cell:focus {
     outline: none!important; 
}


.MuiDataGrid-virtualScroller.css-1grl8tv {
  &::-webkit-scrollbar {
    display: none!important;
  }
}



.css-iclwpf .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
    overflow: visible!important;
}



.MuiDataGrid-cell:focus-within {
    outline: none!important; 
}



.css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate {
  color: #dbbb51!important;
}



.css-iclwpf .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon {
  visibility: hidden!important;
  display: none!important;
}


.css-iclwpf .MuiDataGrid-columnSeparator--sideRight {
  right: -12px;
  opacity: 0!important;
  display: none!important;
}


.cursor-link{
  cursor: pointer;
}