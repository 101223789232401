.my-profile {
  .profile-data {
    height: 652px;
    background: #ffffff;
    border-radius: 14px;
    padding: 30px;

    .profile-title {
      /* The Half Moon */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: $black;
      margin-top: 20px;
    }

    .profile-occupation {
      /* UI/UX Designer */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #5c5c5c;
      margin-top: 4px;
    }

    .profile-mail {
      /* UI/UX Designer */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #5c5c5c;
      margin-top: 2px;
    }

    .image-container {
      width: 100px;
      height: 100px;
      border-radius: 75px;

      img {
        object-fit: cover;
      }
    }
  }

  .profile-form {
    height: 652px;
    background: #ffffff;
    border-radius: 14px;
    padding: 30px;

    .update-btn {
      width: 160px;
      height: 40px;
      margin-top: 20px;
      background: $black;
      color: #dbbb51;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      justify-content: center;
      border-radius: 20px;
      border: 1px solid $black;
    }
  }

  .image-container {
    width: 100px;
    height: 100px;
    border-radius: 75px;

    img {
      object-fit: cover;
    }
  }
}



.contact-update-btn {
  width: 160px;
  height: 40px;
  margin-top: 20px;
  background: $black;
  color: #dbbb51;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid $black;
}