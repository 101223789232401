@media (max-width: 1400px) {
  .patient-section canvas {
    height: 300px !important;
    width: 100% !important;
    margin: auto;
  }

  .dashboard-sidebar {
    padding-left: 10px;
    .logo-container {
      padding-top: 45px;
      padding-bottom: 30px;
      img {
        width: 160px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .dashboard-sidebar {
    padding-left: 10px;
    .logo-container {
      padding-top: 45px;
      padding-bottom: 30px;
      img {
        width: 120px;
      }
    }

    ul {
      text-decoration: none;
      list-style: none;
      // padding-left: 8px;
      padding-left: 0;

      li {
        text-decoration: none;
        color: #899ca8;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        position: relative;

        a {
          text-decoration: none;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          display: flex;
          align-items: center;
          color: #707581;
          line-height: 17px;
          cursor: pointer;
          min-height: 44px;

          .nav-bg {
            padding-left: 5px;
          }

          .settings-icon {
            padding-right: 10px;
          }

          &.active {
            color: #dbbb51;
            font-weight: 700;
            transition: 0.2s ease-out;

            .nav-bg {
              background-color: #fbf8ee;
              width: 100%;
              margin-right: 5px;
              height: 44px;
              display: flex;
              align-items: center;
              border-radius: 20px;
              padding-left: 5px;
            }

            path {
              fill: #dbbb51;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
}

@media (max-width: 810px) {
  .donut-chart-container {
    padding: 20px;
    padding-left: 8px;
  }
}

@media (max-width: 768px) {
  .top-bar {
    display: block;
  }

  .donut-chart-container {
    padding: 30px;
    border-radius: 20px;
  }
}

@media (max-width: 576px) {
  // .input-group
  //   > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
  //     .valid-feedback
  //   ):not(.invalid-tooltip):not(.invalid-feedback) {
  //   margin-left: -1px;
  //   border-top-left-radius: 0;
  //   width: 200px !important;
  //   border-bottom-left-radius: 0;
  // }

  .patient-section {
    padding: 10px 0;
  }

  .donut-chart-container {
    padding: 10px;
    border-radius: 20px;
  }
}

.css-1w5m2wr-MuiDataGrid-virtualScroller {
  &::-webkit-scrollbar {
    display: none!important;
  }
}

.css-v68sna-control {
  border-radius: 20px !important;
  &:focus {
    border-radius: 20px !important;
  }
}

// .afrorierre__control {
//   &--is-focused {
//     border: red;
//     box-shadow: none !important;
//     border-color: unset !important;
//   }
// }
