.settings-sidebar {
  height: 652px;
  background: #ffffff;
  border-radius: 14px;
  padding: 30px 20px;

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $colorgray;
        &.active {
          font-weight: 600;
          color: $base-yellow;

          path {
            fill: $base-yellow;
          }
        }
      }
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background: #ebebf9;
    margin: 0;
    padding: 0;
    margin: 20px 0;
  }
}

.settings-main-section {
  height: 652px;
  background: #ffffff;
  border-radius: 14px;
  padding: 20px;

  .settings-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    /* Black */
    color: $black;
    padding-bottom: 30px;
  }
}

.form-switch .form-check-input {
  height: 31px;
  width: 51px;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: $base-yellow;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-color: $base-yellow;
}

.push-notification-text {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #707581;
}

// Change Password Page Css Start
.change-password-page {
  form {
    .iconfeild{
      background-color: #ffffff;
    }
    .form-floating {
      label {
        font-family: "Ageo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
        padding: 13px 16px;
        &:focus {
          position: absolute;
          top: -10px !important;
        }
      }

      input {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 35px;

        padding: 13px 16px !important;

        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }

      // &:focus {
      //   label {
      //     position: absolute;
      //     top: -10px!important;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //     padding: 1rem 0.75rem;
      //     overflow: hidden;
      //     text-align: start;
      //     text-overflow: ellipsis;
      //     white-space: nowrap;
      //     pointer-events: none;
      //     border: 1px solid transparent;
      //     transform-origin: 0 0;
      //     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
      //     color: blue !important;
      //   }
      // }
      textarea {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 20px;
        padding: 13px 16px !important;
        height: 100px;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
      select {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 20px;
        padding: 13px 16px !important;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
    }
    .form-group {
      margin-bottom: 20px;
    }
    .form-label {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      margin-bottom: 8px;
      background-color: transparent;
    }
    .form-control {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #3a3b3b;
      background: transparent;
      border: 1px solid #ceced4;
      border-radius: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        display: none;
      }
    }
    .form-select {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      background-color: transparent;
      border: 1px solid #ebebf9;
      border-radius: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      background-image: url("../images/BuyerProfile/drop-down-arrow.png");
      background-size: 11px;
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      display: block;
      width: 100%;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
    }
    .button-div {
      display: flex;
      justify-content: end;
      button {
        margin-top: 10px;
        margin-bottom: 40px;
        background: $black;
        border-radius: 20px;
        height: 48px;
        max-width: 200px;
        width: 100%;
        color: #dbbb51;
        text-transform: capitalize;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
        transition: 0.2s ease-in;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: #dbbb51;
          color: #ffffff;
        }
      }
    }
  }
  h3 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    margin-bottom: 30px;
  }

  .password-input {
    input {
      height: 43px;
      border: 1px solid #ceced4;
      border-radius: 20px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #707581;
      border-right: none;
      &:focus {
        box-shadow: none;
        outline: none;
        border-right: none;
      }
    }

    span#basic-addon2 {
      background: white;
      border: 1px solid #ceced4;
      border-left: none;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  .reset-pass-btn {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $black;
    /* Button */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #dbbb51;
    text-transform: capitalize;
    border-radius: 20px;
    border: 1px solid $black;
    margin-top: 20px;
    &:focus {
      background: $base-yellow;
      color: $black;
      border: 1px solid $base-yellow;
    }
  }
}
// Change Password Page Css End

// Terms and Condition Css Start
.terms-and-conditions {
  p {
    font-family: "Ageo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #121212;
  }
}
// Terms and Condition Css End

// Delete Account Css Starts Here

.delete-account {
  .reason-for-deleting {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 30px;
  }

  select.form-select {
    height: 43px;
    border-radius: 30px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #707581;
    &:focus {
      box-shadow: none;
      outline: none;
      border: 1px solid $base-yellow;
    }
  }

  textarea {
    margin-top: 20px;
    border: 1px solid #ceced4;
    border-radius: 14px;
    padding: 12px 16px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #707581;
    &:focus {
      box-shadow: none;
      border: 1px solid $base-yellow;
    }
  }

  .submit-btn {
    width: 160px;
    height: 40px;
    background: $black;
    color: $base-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border: 1px solid $black;
    border-radius: 20px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
  }
}
// Delete Account Css Ends Here

// Contact Us Css Starts Here
.contactus-page {
  .query {
    /* Let us know about your query.. */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    margin-bottom: 30px;
  }
}

.PhoneInputInput {
  border: none;
  min-width: 100%;
  border-left: 2px solid #bbbfcc;
  padding-left: 10px;
  margin-right: 20px;
  /* Phone Number */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.PhoneInput {
  border: 1px solid $colorgray;
  height: 43px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 16px;
  border: 1px solid #d4d4d6;
  border-radius: 20px;
  margin-bottom: 20px;
  &:has(:focus){
    border: 1px solid $base-yellow;
  }
}
// Contact Us Css Ends Here


.ps_12{
  padding-left: 12px;
}