.search-bg {
  /* elm/card/main */
  background: #ffffff;
  border-radius: 14px;
  height: 709px;
}

.search-text {
  /* There are no search results yet. */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #707581;
  margin-top: 16px;
}
