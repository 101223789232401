@font-face {
    font-family: 'Ageo';
    src: url('Ageo-Thin.eot');
    src: local('Ageo Thin'), local('Ageo-Thin'),
        url('Ageo-Thin.eot?#iefix') format('embedded-opentype'),
        url('Ageo-Thin.woff2') format('woff2'),
        url('Ageo-Thin.woff') format('woff'),
        url('Ageo-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-SemiBold.eot');
    src: local('Ageo SemiBold'), local('Ageo-SemiBold'),
        url('Ageo-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Ageo-SemiBold.woff2') format('woff2'),
        url('Ageo-SemiBold.woff') format('woff'),
        url('Ageo-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-SemiBoldItalic.eot');
    src: local('Ageo SemiBold Italic'), local('Ageo-SemiBoldItalic'),
        url('Ageo-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-SemiBoldItalic.woff2') format('woff2'),
        url('Ageo-SemiBoldItalic.woff') format('woff'),
        url('Ageo-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-Regular.eot');
    src: local('Ageo Regular'), local('Ageo-Regular'),
        url('Ageo-Regular.eot?#iefix') format('embedded-opentype'),
        url('Ageo-Regular.woff2') format('woff2'),
        url('Ageo-Regular.woff') format('woff'),
        url('Ageo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-LightItalic.eot');
    src: local('Ageo Light Italic'), local('Ageo-LightItalic'),
        url('Ageo-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-LightItalic.woff2') format('woff2'),
        url('Ageo-LightItalic.woff') format('woff'),
        url('Ageo-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-MediumItalic.eot');
    src: local('Ageo Medium Italic'), local('Ageo-MediumItalic'),
        url('Ageo-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-MediumItalic.woff2') format('woff2'),
        url('Ageo-MediumItalic.woff') format('woff'),
        url('Ageo-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-Bold.eot');
    src: local('Ageo Bold'), local('Ageo-Bold'),
        url('Ageo-Bold.eot?#iefix') format('embedded-opentype'),
        url('Ageo-Bold.woff2') format('woff2'),
        url('Ageo-Bold.woff') format('woff'),
        url('Ageo-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-Medium.eot');
    src: local('Ageo Medium'), local('Ageo-Medium'),
        url('Ageo-Medium.eot?#iefix') format('embedded-opentype'),
        url('Ageo-Medium.woff2') format('woff2'),
        url('Ageo-Medium.woff') format('woff'),
        url('Ageo-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-ExtraBold.eot');
    src: local('Ageo ExtraBold'), local('Ageo-ExtraBold'),
        url('Ageo-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Ageo-ExtraBold.woff2') format('woff2'),
        url('Ageo-ExtraBold.woff') format('woff'),
        url('Ageo-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-HeavyItalic.eot');
    src: local('Ageo Heavy Italic'), local('Ageo-HeavyItalic'),
        url('Ageo-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-HeavyItalic.woff2') format('woff2'),
        url('Ageo-HeavyItalic.woff') format('woff'),
        url('Ageo-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-ExtraBoldItalic.eot');
    src: local('Ageo ExtraBold Italic'), local('Ageo-ExtraBoldItalic'),
        url('Ageo-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-ExtraBoldItalic.woff2') format('woff2'),
        url('Ageo-ExtraBoldItalic.woff') format('woff'),
        url('Ageo-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-RegularItalic.eot');
    src: local('Ageo Regular Italic'), local('Ageo-RegularItalic'),
        url('Ageo-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-RegularItalic.woff2') format('woff2'),
        url('Ageo-RegularItalic.woff') format('woff'),
        url('Ageo-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-BoldItalic.eot');
    src: local('Ageo Bold Italic'), local('Ageo-BoldItalic'),
        url('Ageo-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-BoldItalic.woff2') format('woff2'),
        url('Ageo-BoldItalic.woff') format('woff'),
        url('Ageo-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-Heavy.eot');
    src: local('Ageo Heavy'), local('Ageo-Heavy'),
        url('Ageo-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Ageo-Heavy.woff2') format('woff2'),
        url('Ageo-Heavy.woff') format('woff'),
        url('Ageo-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-ThinItalic.eot');
    src: local('Ageo Thin Italic'), local('Ageo-ThinItalic'),
        url('Ageo-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Ageo-ThinItalic.woff2') format('woff2'),
        url('Ageo-ThinItalic.woff') format('woff'),
        url('Ageo-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Ageo';
    src: url('Ageo-Light.eot');
    src: local('Ageo Light'), local('Ageo-Light'),
        url('Ageo-Light.eot?#iefix') format('embedded-opentype'),
        url('Ageo-Light.woff2') format('woff2'),
        url('Ageo-Light.woff') format('woff'),
        url('Ageo-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

