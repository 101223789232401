.product-slider{
    background: #F5F5F5!important;


    .swiper-testing{
      .mySwiper {
        height: 340px;
        width:100%;
        padding-left: 24px!important;
        box-sizing: border-box;
        padding: 10px 0;
        background: white!important;
        box-shadow: none!important;
        border-radius: 0;
        img{
          width: 57px;
          height: 57px;
          border-radius: 8px;
        }

        .swiper.swiper-initialized.swiper-vertical.swiper-pointer-events.swiper-free-mode.swiper-watch-progress.mySwiper.swiper-thumbs {
          padding-left: 25px;
        }
    }
    }

    
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #F5F5F5!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    background: #F5F5F5!important;

  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
    background: #F5F5F5!important;

  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
    background: #F5F5F5!important;

  }
  
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
    background: #F5F5F5!important;

  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
}


.product-slider .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1!important;
}