.product-listing-banner {
  background-image: url("/src/assets/images/Home/productlisting.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 414px;
  display: flex;
  align-items: center;
  border-radius: 24px;

  .banner-container {
    padding-left: 48px;

    .banner-title {
      /* Everything for your Home */
      width: 346px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: $black;
      margin-bottom: 16px;
    }

    .banner-desc {
      /* New arrivals from handmade masters, Ceramic and aluminum in bright yellow */
      max-width: 346px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #3a3b3b;
      margin-bottom: 24px;
    }

    .banner-button {
      width: 200px;
      height: 40px;
      border-radius: 100px;
      background: $white-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $white-color;
      // btn text
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black;
      &:focus,
      &:hover {
        background: $white-color !important;
        color: $black !important;
        box-shadow: none !important;
        outline: none !important;
        border: 1px solid $white-color;
      }
    }
  }
}

.product-listing-section {
  .section-heading {
    /* Shop */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: $black;
    margin-bottom: 16px;
    margin-top: 64px;
  }
  .filter-btn {
    background: $white-color;
    height: 40px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    // filter txt
    /* All Filters */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    border: 1px solid $border-gray;
    border-radius: 26px;
  }
  button#filter-dropdown {
    background: $white-color;
    font-family: $primary-fonts;
    /* Sort by: Default sorting */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    height: 40px;
    width: 208px;
    border: 1px solid $border-gray;
    border-radius: 26px;
    &:focus,
    &:hover {
      background: white !important;
      box-shadow: none;
    }
  }

  .view {
    /* View: */
    padding-left: 15px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  .view-btn {
    border: 1px solid $border-gray;
    width: 48px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white-color;
    border-radius: 24px;
    margin-left: 8px;

    &:focus,
    &:hover {
      background: $base-yellow;
      outline: none;
      box-shadow: none;
      border: 1px solid $border-gray;
    }
  }
}

.filter-btn-offcanvas {
  width: 545px !important;
  background: #f6f6fa !important;
  padding: 12px 0;
  // top: 238px!important;
  height: 100vh;

  ::-webkit-scrollbar {
    display: none;
  }

  .filter-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #323252;
    margin-left: 87px;
  }

  .filter-conditions-section {
    padding-left: 47px;

    .subheading {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $black;
      margin-top: 22px;
    }

    hr {
      margin: 0;
      height: 1px;
      background: $border-gray;
      margin-top: 16px;
    }

    input[type="radio"]:checked + label {
      background-color: transparent !important;
    }
    label {
      margin-top: 10px;
      margin-left: 8px;
    }
    input {
      margin-top: 12px;
    }

    .form-check-input {
      width: 20px;
      height: 20px;
    }
    .form-check-input:checked {
      background-color: $base-yellow;
      border-color: $base-yellow;
    }
  }

  .cross-btn {
    background: #77778d;
  }

  .origin {
    input {
      width: 232px !important;
    }
  }

  .custom {
    display: flex;
    align-items: center;

    .to {
      /* To */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $black;
      margin: 10px 10px 0 10px;
    }

    input {
      background: $white-color;
      height: 40px;
      width: 100px;
      border-radius: 16px;
      border: 1px solid $white-color;
      display: flex;
      align-items: center;
      justify-content: center;

      &:focus {
        box-shadow: none;
        border: 1px solid $base-yellow;
      }
    }

    ::placeholder {
      font-family: "Ageo";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #8888a2 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    label {
      margin: 0;
      background: #f6f6fa !important;
    }
  }

  .btn-pos{
    margin-top: 80px;
  }

  .apply-btn {
    background: $black;
    width: 200px;
    height: 48px;
    font-family:  $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $white-color;
    border-radius: 100px;
    border: 1px solid $black;
    margin-left: 20px;
    &:focus,&:hover{
      background: rgb(68, 68, 68);
      color: $white-color;
      box-shadow: none;
      outline: none;
      border: 1px solid $black;

    }

  }

  .cancel-btn{
    background: transparent;
    border: 1px solid $black;
    color: $black;
    width: 200px;
    height: 48px;
    font-family:  $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-radius: 100px;

        &:focus,&:hover{
      background: $white-color;
      color: $black;
      box-shadow: none;
      outline: none;
      border: 1px solid $black;
    }
  }
}

// .filter-btn-offcanvas{
//   &.show ~ .offcanvas-backdrop {
//     margin-top: 0;
//   }

// }

.filter-btn-offcanvas.offcanvas.offcanvas-start.show {
  width:450px!important;
}