.product-bg {
  background: white;
  padding: 20px;
  border-radius: 14px;
  min-height: 560px;
  .product-data {
    .product-title {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 24px;
      color: #000000;
      padding-right: 15px;
    }

    .edit-product-btn {
      background: black;
      width: 160px;
      height: 40px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      color: #dbbb51;
      border: none;
      box-shadow: none;
      border-radius: 20px;
    }

    .plus-btn {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
      margin-left: 10px;
      cursor: pointer;
    }

    .product-discount-price {
      /* $120.20 */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      /* Black */
      color: #000000;
    }

    .product-original-price {
      /* $150.45 */
      font-family: "Ageo";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;
      color: #707581;
      padding-left: 15px;
    }

    .review-section {
      margin-top: 15px;
    }

    .rating-reviews {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.02em;
      padding-left: 10px;
    }

    .tags-section {
      margin-top: 15px;

      :last-child {
        margin-right: 0;
      }
    }

    .tags {
      background: #f5e7bd;
      min-width: 62px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* Women */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #3a3b3b;
      border-radius: 8px;
      margin-right: 10px;
      padding: 0 11px;
      // :first-child{
      //   margin-left: 0;
      //   background: blue!important;
      // }
    }

    // Made in section css start
    .made-in-section {
      width: 343px;
      height: 60px;
      background: #f6f6fa;
      border-radius: 10px;
      margin-top: 15px;
      padding: 0 10px;
      .heading {
        /* Made In */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #a7a7a8;
      }

      .seperator-line {
        height: 40px;
        border-left: 0.5px solid #ceced4;
      }

      .no-of-orders {
        /* 360 */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
      }
    }

    .buyer-product-rating {
      /* 4.0 (258) */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $black;
    }

    .reviews-num {
      color: #707581;
      padding-left: 5px;
    }

    // Made in section css end

    // product description css starts here

    .product-description {
      .description-heading {
        /* Description: */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-top: 20px;
      }

      ul {
        margin-top: 10px;
        padding-left: 20px;
        max-width: 545px;

        li {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          letter-spacing: 0.02em;
          color: #707581;
        }
      }
    }

    // product description css ends here

    // product other info css starts here
    .productinfo {
      .other-info {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: $black;
      }

      ul {
        padding-left: 20px;
        li {
          font-size: 14px;
          font-weight: 500;
          padding-top: 11px;
          font-family: $primary-fonts;
          color: #707581;

          span {
            color: black;
          }
        }
      }
    }
    // product other info css ends here
  }
}

.product-slider {
  background: white !important;
}

.product-table-data {
  margin-left: 10px;
  .product-name {
    width: 186px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .product-no {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #707581;
    margin-top: 3px;
  }
}


.product-management-rating{
  .productstarrating{
    font-size: 14px;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 5px;
    color: $base-gray!important;

  }
  .productstarsubrating{
    font-size: 14px;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 2px;
    color: $base-gray!important;
  }
}


