.review-order-modal {
  .modal-dialog {
    .modal-content {
      background: #ffffff;
      box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
      border-radius: 14px;
      max-width: 570px;
      height: 428px;
      width: 100%;
      margin: 0 auto;

      .modal-header {
        padding-bottom: 13px;

        border-color: #e3e3f1;
      }
      h4 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        padding-left: 24px;
      }
      .close-div {
        button {
          background-color: transparent;
          background-color: #f9fafa;
          height: 43px;
          text-align: center;
          border-radius: 13px;
          border: none;
          margin-right: 22px;
        }
      }

      .modal-body {
        padding-top: 0px;

        .main-rating-div {
          display: flex;
          align-items: center;
          .start-rating-section {
            justify-content: center;
            display: flex;
            align-items: center;
            padding-left: 24px;

            .stars {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              flex-direction: row-reverse;
              justify-content: center;
            }
            .react-stars {
              overflow: hidden;
              position: relative;
              bottom: 10px;
              span{
                padding-right: 12px;
              }
            }
            // .stars__star-icon {
            //   stroke: #efce4a;
            //   stroke-width: 2px;
            //   fill: transparent;
            //   transition: 0.1s all;
            // }

            // .stars__star {
            //   width: 75px;
            //   height: 75px;
            //   position: relative;
            //   cursor: pointer;
            //   margin: 10px;
            // }

            // .stars__checkbox {
            //   position: absolute;
            //   top: -9999px;
            //   opacity: 0;
            //   width: 0;
            //   height: 0;
            // }

            // .stars__star:hover > .stars__star-icon {
            //   fill: #efce4a;
            // }

            // .stars__star:hover ~ .stars__star > .stars__star-icon {
            //   fill: #efce4a;
            // }

            // .stars__checkbox:checked + .stars__star > .stars__star-icon {
            //   fill: #efce4a;
            // }

            // .stars__checkbox:checked ~ .stars__star > .stars__star-icon {
            //   fill: #efce4a;
            // }
          }
        }
        .buyer-contact-fields {
          form {
            padding-left: 24px;
            padding-right: 24px;
            .form-floating {
              label {
                font-family: "Ageo";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #707581;
                padding: 13px 16px;
                &:focus {
                  position: absolute;
                  top: -10px !important;
                }
              }

              input {
                width: 100%;
                height: 43px;
                border: 1px solid #ceced4;
                border-radius: 20px;
                display: flex;
                align-items: center;
                font-family: "Ageo";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                margin-bottom: 25px;

                padding: 13px 16px !important;

                &:focus {
                  outline: none;
                  box-shadow: none;
                  border: 1px solid $base-yellow;

                  label {
                    color: blue !important;
                  }
                }

                &:not(:placeholder-shown) ~ label {
                  background: #ffffff;
                  padding: 0;
                  margin: 0;
                  top: 0;
                  opacity: 1;
                  display: inline;
                  width: auto;
                  height: auto;
                  left: 12px;
                  padding: 0 8px;
                }

                &:focus ~ label {
                  background: #ffffff;
                  padding: 0;
                  margin: 0;
                  top: 0;
                  opacity: 1;
                  display: inline;
                  width: auto;
                  height: auto;
                  left: 12px;
                  padding: 0 8px;
                }
              }

              textarea {
                width: 100%;
                height: 43px;
                border: 1px solid #ceced4;
                border-radius: 20px;
                display: flex;
                align-items: center;
                font-family: "Ageo";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                margin-bottom: 20px;
                padding: 13px 16px !important;
                height: 100px;
                resize: none;
                &::-webkit-scrollbar {
                  display: none;
                }
                &:focus {
                  outline: none;
                  box-shadow: none;
                  border: 1px solid $base-yellow;

                  label {
                    color: blue !important;
                  }
                }

                &:not(:placeholder-shown) ~ label {
                  background: #ffffff;
                  padding: 0;
                  margin: 0;
                  top: 0;
                  opacity: 1;
                  display: inline;
                  width: auto;
                  height: auto;
                  left: 12px;
                  padding: 0 8px;
                }

                &:focus ~ label {
                  background: #ffffff;
                  padding: 0;
                  margin: 0;
                  top: 0;
                  opacity: 1;
                  display: inline;
                  width: auto;
                  height: auto;
                  left: 12px;
                  padding: 0 8px;
                }
              }
            }
            .form-group {
              margin-bottom: 20px;
            }
            .form-label {
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: $black;
              margin-bottom: 8px;
              background-color: transparent;
            }
            .form-control {
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #3a3b3b;
              background: transparent;
              border: 1px solid #ceced4;
              border-radius: 16px;
              padding-top: 14px;
              padding-bottom: 14px;
              padding-left: 20px;
              &:focus {
                box-shadow: none;
              }

              &::placeholder {
                display: none;
              }
            }

            .button-div {
              display: flex;
              justify-content: end;
              button {
                margin-top: 10px;
                margin-bottom: 40px;
                background: $black;
                border-radius: 100px;
                height: 48px;
                max-width: 160px;
                width: 100%;
                color: #dbbb51;
                text-transform: capitalize;
                font-family: $primary-fonts;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                border: none;
                transition: 0.2s ease-in;
                &:focus {
                  box-shadow: none;
                }
                &:hover {
                  background-color: #dbbb51;
                  color: #ffffff;
                }
              }
            }
          }
        }
        .border-bottomm {
          border-bottom: 1px solid #e3e3f1;
          margin-bottom: 19px;
        }
        textarea {
          height: 120px;
          max-width: 521px;
          resize: none;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #707581;
          &:focus {
            box-shadow: none;
            outline: none;

            border-color: #dbbb51;
          }
        }
      }
      //   .submit-div {
      //     button {
      //       display: block;
      //       background: #000000;
      //       border-radius: 20px;
      //       margin-left: auto;
      //       margin-top: 20px;
      //       max-width: 160px;
      //       height: 48px;
      //       width: 100%;
      //       border: none;
      //       color: #dbbb51;
      //       font-family: $primary-fonts;
      //       font-style: normal;
      //       font-weight: 700;
      //       font-size: 16px;
      //       line-height: 19px;
      //       transition: 0.1s ease-in;
      //       &:hover {
      //         background: #dbbb51;
      //         color: #ffffff;
      //       }
      //     }
      //   }
    }
  }
}
