.add-to-cart-modal {
  .modal-dialog {
    .modal-content {
      background: #ffffff;
      box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
      border-radius: 14px;
      max-width: 570px;
      height: 667px;
      min-width: 570px;
      .modal-header {
        border: none;
      }
      h4 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
      }
      .close-div {
        button {
          background-color: transparent;
          background-color: #f9fafa;
          height: 43px;
          text-align: center;
          border-radius: 13px;
          border: none;
        }
      }

      .modal-body {
        padding: 0;
        .add-to-cart {
          hr {
            background: #e3e3f1;
            height: 1px;
            width: 100%;
            margin: 0;
            margin-bottom: 24px;
          }

          .content-section {
            padding: 0 24px;
            .productimg-container {
              /* Product Image */
              width: 94px;
              height: 94px;
              img {
                border-radius: 16px;
                object-fit: cover;
                height: 100%;
                width: 100%;
              }
            }
            .product-title {
              /* Black Minimalist Wall Clock */
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: black;
              margin-bottom: 17px;
            }
            .product-price {
              /* $120.20 */
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 31px;
              align-items: center;
              color: $black;
              margin-bottom: 30px;
              // padding-left: 20px;
              text-align: right;
            }

            .delete-text {
              /* Delete */
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: $delete-txt;
              padding-left: 8px;
            }

            .also-recommend {
              /* Is also recommended */
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: $black;
              margin-bottom: 20px;
            }
            .product-card-container {
              width: 170px;
              height: 170px;
              border-radius: 16px;
              position: relative;
              margin: 0 auto;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 16px;
              }
              .card-title {
                /* Flower clock */
                font-family: $primary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $black;
                margin-top: 8px;
              }
              .white-circle {
                height: 48px;
                width: 48px;
                border-radius: 24px;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 8px;
                right: 8px;
              }
            }
            .cancel-div {
              button {
                display: block;
                background: $white-color;
                border-radius: 20px;
                margin-left: auto;
                margin-top: 15px;
                max-width: 100%;
                height: 48px;
                width: 100%;
                border: none;
                color: $black;
                border: 1px solid $black;
                font-family: $primary-fonts;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                transition: 0.1s ease-in;
                margin-bottom: 20px;
                &:hover {
                  background: #dbbb51;
                  color: #ffffff;
                }
              }
            }
            .submit-div {
              button {
                display: block;
                margin-top: 50px;
                background: $black;
                border-radius: 20px;
                margin-left: auto;
                max-width: 100%;
                height: 48px;
                width: 100%;
                border: none;
                color: $base-yellow;
                border: 1px solid $black;
                font-family: $primary-fonts;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                transition: 0.1s ease-in;
                &:hover {
                  background: #dbbb51;
                  color: #ffffff;
                  border: 1px solid $base-yellow;
                }
              }
            }
          }
        }
      }
    }
  }
}
