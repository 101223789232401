.css-17jjc08-MuiDataGrid-footerContainer {
  display: none;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  display: none !important;
}

.MuiDataGrid-footerContainer.css-1yy0dv2 {
  display: none;
}

.pagination-border {
  border-top: 1px solid rgb(224, 224, 224);
  padding-top: 20px;
}

.text-red-two {
  color: red;
  position: absolute;
  top: 47px;
  font-size: 11px;
  left: 9px;
}

// Legends of donut chart css starts here

.dark-golden-legend {
  height: 18px;
  width: 18px;
  background: #dbbb51;
}

.light-golden-legend {
  height: 18px;
  width: 18px;
  background: #faeec6;
}

.legend-text {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #707581;
  padding-left: 20px;
}
// Legends of donut chart css ends here

// donut chart container css starts here

.donut-chart-container canvas {
  width: 170px !important;
  margin: auto;
  height: 170px !important;
  margin-top: 50px;
}

.mt_40 {
  margin-top: 40px;
}

// Donut chart css ends here

.main-buyer-contact {
  input.PhoneInputInput {
    background: transparent;
  }
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row {
  &:hover {
    background-color: rgba(219, 187, 81, 0.08) !important;
  }
}

.show-all {
  /* Show all */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #77778d;
  cursor: pointer;
}

input#floatingInput {
  margin-top: 4px;
}

select#floatingSelect {
  margin-top: 4px;
}

.PhoneInput {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.signup-form .form-floating select {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  resize: none;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.dashboard-dropdown {
  .css-qbdosj-Input {
    width: 100px;
  }
  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 120px !important;
    height: 40px !important;
  }
}

.month-dropdown {
  .css-qbdosj-Input {
    width: 100px;
  }
  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 140px !important;
    height: 40px !important;
  }
}

.dashboard-main-heading {
  /* Dashboard */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #121212;
  margin-bottom: 20px;
  margin-top: 40px;
}

.start-date {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

@media (max-width: 1400px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;

    textarea {
      border: 1px solid #ceced4;
      border-radius: 20px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ceced4;
      width: 520px;
      min-height: 43px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: none;
      border: 1px solid $colorgray;
      margin: 0 10px;

      resize: none;

      &:focus {
        box-shadow: none;
        border: 1px solid $colorgray;
        padding-top: 18px !important;
        padding-left: 10px !important;
        ::placeholder {
          display: none;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    textarea::placeholder {
      padding-top: 18px !important;
      padding-left: 10px !important;
      position: absolute;
    }
    textarea:focus::placeholder {
      color: transparent;
    }
  }
}

@media (max-width: 1250px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;

    textarea {
      border: 1px solid #ceced4;
      border-radius: 20px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ceced4;
      width: 380px;
      min-height: 43px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: none;
      border: 1px solid $colorgray;
      margin: 0 10px;
      resize: none;
    }
  }
}

@media (max-width: 850px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;

    textarea {
      border: 1px solid #ceced4;
      border-radius: 20px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ceced4;
      width: 250px;
      min-height: 43px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: none;
      border: 1px solid $colorgray;
      margin: 0 10px;
      resize: none;
    }
  }
}

@media (max-width: 400px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;

    textarea {
      border: 1px solid #ceced4;
      border-radius: 20px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ceced4;
      width: 150px;
      min-height: 43px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: none;
      border: 1px solid $colorgray;
      margin: 0 10px;
      resize: none;
    }
  }
}

@media (max-width: 500px) {
  .bg-salmon {
    height: 180px;
    .product-desc {
      font-size: 14px !important;
    }
  }
}

.css-cc8tf1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Ageo"!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 12px!important;
  line-height: 14px!important;
  color: #979797!important;
}

.css-cc8tf1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Ageo"!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 12px!important;
  line-height: 14px!important;
  color: #979797!important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.order-detail-table-text {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.order-est-delivery-time {
  /* (Est. delivery 15-17 Oct) */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0038em;
  color: #707581;
  margin-top: 10px;
}


@media (max-width: 450px){
  .shop-container {
    height: 414px;
    border-radius: 24px;
    background-image: url("/src/assets/images/Home/Product\ ADS.png");
    background-size: cover;
  
    .product-details {
      padding-top: 110px;
      padding-left: 12px;

  
    }
  }
}

@media (max-width:576px){
  .filter-btn-offcanvas .apply-btn{
    margin-left: 0;
  }

  .table-search-box input {
    background: #fbfbfd;
    border: none;
    width: 190px !important;

}
}

@media (max-width:410px){
  .product-bg .product-data .made-in-section{
    width:100%;
  }
}

.details-description-width{
  width:50%!important;
}

@media (max-width:1200px){
  .details-description-width{
    width:100%!important;
  }
}