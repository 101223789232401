.add-new-modal {
    .modal-dialog {
      .modal-content {
        background: #ffffff;
        box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
        border-radius: 14px;
        max-width: 372px;
        height: 465px;
        width: 100%;
        padding-left: 21px;
        padding-right: 21px;
        .modal-header {
          border: none;
        }
        h4 {
          margin-bottom: 0;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 26px;
          color: #000000;
        }
        .close-div {
          button {
            background-color: transparent;
            background-color: #f9fafa;
            height: 43px;
            text-align: center;
            border-radius: 13px;
            border: none;
          }
        }
        .modal-body {
          textarea {
            height: 128px;
            max-width: 387px;
            resize: none;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #707581;
            &:focus {
              box-shadow: none;
              outline: none;
  
              border-color: #dbbb51;
            }
            &::placeholder {
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #707581;
            }
          }
        }
        .submit-div {
          button {
            display: block;
            background: #000000;
            border-radius: 20px;
            margin-left: auto;
            margin-top: 20px;
            max-width: 160px;
            height: 48px;
            width: 100%;
            border: none;
            color: #dbbb51;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            transition: .1s ease-in;
            &:hover {
              background: #dbbb51;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  