@charset "UTF-8";
@import url(c:\Afrotierre\afrotierre-marketplace\src\assets\fonts\Ageo\stylesheet.css);
.mt_10 {
  margin-top: 10px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_24 {
  margin-top: 24px;
}

.mt_30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_60 {
  margin-top: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_40 {
  margin-bottom: 40px;
}

.ms_16 {
  margin-left: 16px;
}

.ms_50 {
  margin-left: 50px;
}

.main-section .container {
  max-width: 1200px;
}

.LineGraph button:not(:disabled) {
  cursor: pointer;
  background: #f6f8fa;
  border: none;
  text-align: right;
  height: 48px;
  padding: 0 10px;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #363636;
}

.patient-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px;
  height: 414px;
}

.patient-section .patients {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #363636;
  padding: 20px 0 0 30px;
}

.patient-section canvas {
  height: 60% !important;
  width: 80% !important;
  margin: auto;
}

.donut-chart-container {
  background: #ffffff;
  border-radius: 14px;
  height: 414px;
  padding: 20px;
  padding-top: 30px;
}

.donut-chart-container canvas {
  height: 100% !important;
  width: 230px !important;
  margin: auto;
  margin-top: 50px;
}

.containers-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: black;
}

.dropdown-custom-btn {
  text-align: right;
}

.dropdown-custom-btn select.form-select {
  border: 1px solid #707581;
  border-radius: 20px;
  color: #707581;
  height: 40px;
  min-width: 120px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.dropdown-custom-btn button#dropdown-basic {
  background: white;
  border: 1px solid #707581;
  border-radius: 20px;
  color: #707581;
  height: 40px;
  min-width: 120px;
  text-overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* Button */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  color: #707581;
}

.dropdown-custom-btn .dropdown-menu.show {
  height: 140px;
  overflow: scroll;
}

.dropdown-custom-btn .dropdown-menu.show ::-webkit-scrollbar {
  display: none;
}

.table-container {
  background: #ffffff;
  border-radius: 14px;
  height: 100%;
  margin-top: 30px;
}

.order-table-container {
  min-height: 637px;
  background: #ffffff;
  border-radius: 14px;
  padding: 24px 20px;
}

.datagrid-table {
  border: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator,
.css-ptiqhd-MuiSvgIcon-root {
  display: block !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: inherit;
  display: none !important;
}

.css-1e2bxag-MuiDataGrid-root
.MuiDataGrid-columnHeader:hover
.MuiDataGrid-menuIcon {
  display: none !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #a1a0a0;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
}

.product-table-title {
  /* PN0001265 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #363636;
  margin-left: 10px;
}

.order-table .MuiDataGrid-columnHeaders.css-f3jnds-MuiDataGrid-columnHeaders {
  padding-left: 20px !important;
}

.order-table .MuiDataGrid-virtualScrollerContent.css-1kwdphh-MuiDataGrid-virtualScrollerContent {
  padding-left: 20px !important;
}

.order-table .MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter {
  display: none !important;
}

.order-table .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  display: none !important;
}

.order-table .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeaderCheckbox {
  display: none !important;
}

.order-table .css-iclwpf .MuiDataGrid-menuIcon {
  display: none !important;
}

.order-table .css-iclwpf .MuiDataGrid-columnSeparator {
  display: none !important;
}

.order-table .MuiDataGrid-columnHeaders.css-1nitb7c {
  padding-left: 20px !important;
}

.order-table .MuiDataGrid-virtualScrollerRenderZone.css-1inm7gi {
  padding-left: 20px !important;
}

.dispatched {
  width: 100px;
  height: 26px;
  background-color: #fff8ed;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  color: #ff9900;
}

.instock {
  background: #ecfff6;
  width: 100px;
  height: 26px;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  color: #069952;
}

.outstock {
  background: #fff8ed;
  width: 100px;
  height: 26px;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #ff9900;
}

.inprocess {
  width: 100px;
  height: 26px;
  background-color: #edf9ff;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  color: #09acfe;
}

.completed {
  width: 100px;
  height: 26px;
  background-color: #ecfff6;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  color: #069952;
}

.order-table-section {
  background: white;
  border-radius: 14px;
  padding-top: 30px;
  margin-top: 30px;
}

.order-table-section .order-title {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-left: 20px;
}

.view-all-btn {
  width: 108px;
  height: 40px;
  background: white;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: capitalize;
  color: #707581;
  margin-right: 20px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.view-all-btn:focus, .view-all-btn:hover {
  background: white !important;
  color: #707581 !important;
}

.main-title-heading {
  margin-top: 30px;
  margin-bottom: 30px;
  /* Dashboard */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #121212;
}

.main-subheading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #121212;
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.user-button button#dropdown-basic-button {
  width: 30px;
  height: 30px;
  background: #fafafb;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.user-button button#dropdown-basic-button:focus {
  border: none;
}

.user-button .dropdown-toggle::after {
  display: none;
}

.vertical-btn button#dropdown-basic-button {
  background: transparent;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.vertical-btn button#dropdown-basic-button:focus {
  border: none;
}

.vertical-btn .dropdown-toggle::after {
  display: none;
}

.css-1e2bxag-MuiDataGrid-root
.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
> .MuiDataGrid-cell {
  overflow: visible !important;
  white-space: nowrap;
}

.css-1e2bxag-MuiDataGrid-root
.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
> .MuiDataGrid-cell:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: rgba(219, 187, 81, 0.08) !important;
}

.dropdown-menu.show {
  padding: 15px;
  /* Dashboard */
  padding-left: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707581;
  border: 1px solid #dbbb51;
  min-width: 112px;
}

.nav-multidropdown {
  position: relative;
}

.nav-multidropdown .test {
  top: 20px;
  display: none;
  position: absolute;
  background: #fdfdff;
  height: 628px;
  width: 322px;
  z-index: 999;
  padding-top: 15px;
}

.nav-multidropdown .test .new-test {
  padding: 8px 15px;
}

.nav-multidropdown .test .new-test .new-test-result {
  display: none;
  position: absolute;
  background: #fdfdff;
  width: 400px;
  height: 628px;
  left: 320px;
  top: 0;
  border-left: 1px solid #e3e3f1;
  padding-top: 15px;
  padding-left: 30px;
}

.nav-multidropdown .test .new-test:hover, .nav-multidropdown .test .new-test:focus {
  background: #f6f6fa;
}

.nav-multidropdown .test .new-test:hover .new-test-result, .nav-multidropdown .test .new-test:focus .new-test-result {
  display: block;
}

.nav-multidropdown:hover .test, .nav-multidropdown:focus .test {
  display: block;
}

.dropdown-main-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding-left: 15px;
  margin-bottom: 8px;
}

.dropdown-all-home {
  margin-top: 16px;
}

.dropdown-all-home .dropdown-all-home-text {
  font-family: "Ageo", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  padding-right: 60px;
}

.dropdown-menu-option {
  /* Home */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.second-col-box {
  border-left: 1px solid #e3e3f1;
  padding-left: 30px;
}

.mb_24 {
  margin-bottom: 24px;
}

.mt_16 {
  margin-top: 16px;
}

.ml_7 {
  margin-left: 7px;
}

.dropdown-menu-sub-option {
  /* Wall Decor */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 16px;
}

.dropdown-menu-sub-option a {
  color: #000000;
}

.buyer-navbar-profile {
  background: #fdfdff;
  padding: 4px;
  width: 76px !important;
  height: 48px;
  border-radius: 100px;
  padding-left: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
          box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
}

.buyer-navbar-profile .img-box {
  width: 40px;
  height: 40px;
  border-radius: 24px;
}

.iconfeild {
  position: absolute;
  bottom: 10px;
  right: 13px;
  background-color: #f6f6fa;
  width: 30px;
  text-align: end;
}

.toggle-password {
  cursor: pointer;
}

.position-realtive {
  position: relative;
}

.confirmed {
  color: #09ACFE !important;
}

.pending {
  color: #DBBB51 !important;
}

.completedd {
  color: #069952 !important;
}

.cancelled {
  color: #C6000F !important;
}

.text-red {
  color: red;
  position: absolute;
  top: 44px;
  font-size: 11px;
  left: 15px;
}

.css-iclwpf .MuiDataGrid-row.Mui-selected {
  background-color: rgba(219, 187, 81, 0.08) !important;
}

.css-iclwpf .MuiDataGrid-columnHeader:focus, .css-iclwpf .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-virtualScroller.css-1grl8tv::-webkit-scrollbar {
  display: none !important;
}

.css-iclwpf .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
  overflow: visible !important;
}

.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate {
  color: #dbbb51 !important;
}

.css-iclwpf .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon {
  visibility: hidden !important;
  display: none !important;
}

.css-iclwpf .MuiDataGrid-columnSeparator--sideRight {
  right: -12px;
  opacity: 0 !important;
  display: none !important;
}

.cursor-link {
  cursor: pointer;
}

.navbar-bg {
  background-color: #f6f6fa;
  padding-top: 24px;
}

.navbar-bg a.navbar-brand {
  margin-bottom: 20px;
}

.navbar-bg .divider hr {
  /* Separator */
  height: 1px;
  background: #e3e3f1;
  border-radius: 8px;
  margin-top: 0;
}

.navbar-bg .search-bar input.form-control {
  border-radius: 14px;
  border: none;
  border: 1px solid #e3e3f1;
  border-right: none;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8888a2;
  padding: 14px;
  max-width: 100%;
  height: 48px;
  background: white;
  border-radius: 14px;
}

.navbar-bg .search-bar span#basic-addon2 {
  background: wheat;
  background: white;
  border: 1px solid #e3e3f1;
  border-left: none;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.navbar-bg .signin-btn {
  background: #dbbb51;
  width: 79px;
  height: 48px;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #020203;
  border: none;
  border-radius: 20px;
}

.navbar-bg .signin-btn:focus, .navbar-bg .signin-btn:hover {
  background: #1e1d1c;
  color: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.navbar-bg .heart-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  width: 48px;
  height: 48px;
  background: #fdfdff;
  -webkit-box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
          box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
  border-radius: 24px;
}

.navbar-bg .header-bottombar {
  margin-top: 24px;
  padding-bottom: 14px;
}

.navbar-bg .header-bottombar .category-btn {
  border-radius: 100px;
  width: 129px;
  height: 48px;
  background: #fdfdff;
  border: 1px solid #fdfdff;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #020203;
  margin-right: 16px;
}

.navbar-bg .header-bottombar .menu-options ul {
  list-style: none;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.navbar-bg .header-bottombar .menu-options ul li {
  margin-right: 17px;
  /* Health & Beauty */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 10px;
}

.footer-bg {
  background: #fff1c7;
  padding-top: 24px;
}

.footer-bg .footer-title {
  /* Health & Beauty */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #000000;
}

.footer-bg .title-type {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-top: 16px;
  cursor: pointer;
  max-width: 150px;
}

.footer-bg .title-type a {
  text-decoration: none;
  color: #000000;
}

.footer-bg .download-app-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #020203;
}

.footer-bg .download-app-btn {
  width: 178px;
  max-height: 50px;
  background: #000000;
  border-radius: 10px;
  margin-top: 9px;
  padding: 6px 16px;
  cursor: pointer;
}

.footer-bg .download-app-btn p {
  font-family: "Ageo", sans-serif;
  font-size: 12px;
  color: #fdfdff;
  margin-bottom: 0;
}

.footer-bg .download-app-btn h2 {
  font-size: 20px;
  color: #fdfdff;
  font-family: "Ageo", sans-serif;
  margin-bottom: 0;
  margin-top: -5px;
}

.footer-bg .download-app-btn:hover path, .footer-bg .download-app-btn:focus path {
  fill: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer-bg .download-app-btn:hover p, .footer-bg .download-app-btn:focus p {
  color: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer-bg .download-app-btn:hover h2, .footer-bg .download-app-btn:focus h2 {
  color: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer-bg .connect-with-us {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #020203;
  margin-bottom: 12px;
  margin-right: 60px;
}

.footer-bg .playstore-padding {
  margin-top: 44px;
}

.footer-bg .connect-logo {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: #1e1d1c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.footer-bg .connect-logo path {
  fill: #fdfdff;
}

.footer-bg .connect-logo:hover path, .footer-bg .connect-logo:focus path {
  fill: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer-bottom {
  background: #f6f6fa;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-bottom ul li {
  text-decoration: none;
  list-style: none;
  margin-left: 24px;
}

.footer-bottom ul li a {
  text-decoration: none;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* Text/Black-100 */
  color: #020203;
}

.footer-bottom .copyright-text {
  /* noon. All Rights Reserved */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #77778d;
}

.footer-bottom .help-btn {
  background: #77778d;
  width: 90px;
  height: 40px;
  border-radius: 26px;
  font-family: "Poppins", "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #fdfdff;
  border: none;
}

.footer-bottom .help-btn:focus, .footer-bottom .help-btn:hover {
  background: #1e1d1c;
  color: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer-bottom .help-btn:focus path, .footer-bottom .help-btn:hover path {
  fill: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product-card-bg {
  min-width: 270px;
  min-height: 350px;
  background: #f6f6fa;
  border-radius: 16px;
}

.product-card-bg .img-container {
  position: relative;
}

.product-card-bg .top-right {
  position: absolute;
  top: 8px;
  right: 8px;
}

.product-card-bg .bottom-right {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.product-card-bg .bottom-left {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.product-card-bg .price-tag {
  width: 105px;
  height: 40px;
  background: #fdfdff;
  border: 1px solid #dbbb51;
  border-radius: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.product-card-bg .price-tag .price {
  /* $122 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.product-card-bg .price-tag strike {
  color: red;
}

.product-card-bg .price-tag .original-price {
  /* $154 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8888a2 !important;
  margin-left: 8px;
}

.product-card-bg .icon-container {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fdfdff;
  border-radius: 24px;
  cursor: pointer;
}

.product-card-bg .product-desc {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-top: 12px;
  padding: 0 12px;
  padding-bottom: 10px;
}

.product-category-heading {
  width: 270px;
  height: 29px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.product-category-desc {
  /* All Categories */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #77778d;
}

.mb_8 {
  margin-bottom: 8px;
}

.mb_16 {
  margin-bottom: 16px;
}

.banner-bg {
  background-image: url("/src/assets/images/Home/Banner.png");
  height: 510px;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.shop-container {
  height: 414px;
  border-radius: 24px;
  background-image: url("/src/assets/images/Home/Product ADS.png");
  background-size: cover;
}

.shop-container .product-details {
  padding-top: 110px;
  padding-left: 48px;
}

.shop-container .product-details h2 {
  /* Handmade decorative teardrops */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #323252;
  margin: 0;
  width: 346px;
}

.shop-container .product-details p {
  /* New arrivals from handmade masters, Ceramic and aluminum in bright yellow */
  width: 346px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323252;
  margin: 0;
  margin-top: 16px;
  width: 320px;
}

.prev-next-btn {
  background: #fdfdff;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
}

.prev-next-btn:focus, .prev-next-btn:hover {
  border: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none;
  background: #fdfdff !important;
}

.navigation-btns {
  position: absolute;
  right: 24px;
  z-index: 9;
  bottom: 24px;
  width: 88px;
}

.product-card-shop {
  height: 414px;
  border-radius: 24px;
  background: url(/src/assets/images/Home/productimg.png);
  background-repeat: no-repeat;
  padding-top: 110px;
  padding-left: 48px;
  -webkit-box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
          box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
}

.product-card-shop h2 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #323252;
  margin: 0;
  width: 222px;
}

.product-card-shop p {
  /* Handmade table decorations for the upcoming Halloween */
  width: 222px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323252;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 39px;
}

.shop-btn {
  width: 200px;
  height: 48px;
  background: #f7f7f9;
  -webkit-box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
          box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
  border-radius: 100px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323252;
  border: none;
  margin-top: 24px;
}

.shop-btn:hover, .shop-btn:focus {
  background: #f7f7f9 !important;
  color: #323252 !important;
}

.swiper {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
          box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
  border-radius: 24px;
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.auth-container {
  height: 730px;
  background: url("/src/assets/images/Auth/authbg.png");
  background-repeat: no-repeat;
  background-position: right;
  margin: auto;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1110px;
}

.auth-container hr {
  opacity: 0.2;
  /* Logo Yellow */
  border: 1px solid #dbbb51;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.auth-container h2 {
  /* Welcome to Afrotierre */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin: 0;
}

.auth-container h4 {
  /* Select Account Type */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin: 0;
  margin-top: 30px;
}

.auth-container h6 {
  /* Is your Afrotierre account Buyer or Seller? */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
  margin-top: 30px;
}

.auth-container h6 span {
  font-weight: 700;
}

.auth-container .new-hr {
  width: 120px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.auth-container .auth-btn {
  /* Button */
  /* Auto layout */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 160px;
  height: 40px;
  display: inline-block;
  /* Black */
  background: #000000;
  border-radius: 20px;
  border: none;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #dbbb51;
  margin-top: 40px;
}

.auth-container .auth-btn:focus {
  background: #000000;
}

.auth-container .auth-btn:hover {
  background: #dbbb51;
  color: black;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.auth-container .already-account {
  /* Already have an account? */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-right: 8px;
  color: #5c5c5c;
  margin-top: 4px;
}

.auth-container .already-acc-link {
  /* Sign In */
}

.auth-container .already-acc-link a {
  text-decoration: none;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
}

.auth-container .already-acc-link a:hover {
  color: #dbbb51;
}

.auth-container .as-guest {
  margin-top: 20px;
  margin-bottom: 20px;
}

.auth-container .as-guest a {
  text-decoration: none;
  /* Continue as guest */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* Grey_dark */
  color: #979797;
}

.auth-container .as-guest a:hover {
  color: #dbbb51;
}

.select-role input {
  display: none;
}

.select-role label:checked .icon-container {
  background: black;
}

.select-role label {
  width: 350px;
  height: 90px;
  border-radius: 20px;
  padding: 15px 0 15px 20px;
  background: #f6f6fa;
  border: 1px solid #ceced4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.select-role label .icon-container {
  background: white;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 15px;
}

.select-role label .register-as {
  /* Register as a Buyer */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
}

input[type="radio"]:checked + label {
  background-color: #dbbb51;
}

input[type="radio"]:checked .icon-container {
  background: black;
}

.signup-form .form-floating label {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding: 13px 16px;
}

.signup-form .form-floating label:focus {
  position: absolute;
  top: -10px !important;
}

.signup-form .form-floating textarea {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  height: 100px;
  resize: none;
}

.signup-form .form-floating textarea::-webkit-scrollbar {
  display: none;
}

.signup-form .form-floating textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.signup-form .form-floating textarea:focus label {
  color: blue !important;
}

.signup-form .form-floating textarea:not(:placeholder-shown) ~ label {
  background: white;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.signup-form .form-floating textarea:focus ~ label {
  background: white;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.signup-form .form-floating input {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 35px;
  padding: 13px 16px !important;
}

.signup-form .form-floating input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.signup-form .form-floating input:focus label {
  color: blue !important;
}

.signup-form .form-floating input:not(:placeholder-shown) ~ label {
  background: white;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.signup-form .form-floating input:focus ~ label {
  background: white;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 5px;
}

.signup-form .form-floating select {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 30px;
  padding: 13px 16px !important;
  resize: none;
}

.signup-form .form-floating select::-webkit-scrollbar {
  display: none;
}

.signup-form .form-floating select:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.signup-form .form-floating select:focus label {
  color: blue !important;
}

.signup-form .form-floating select:not(:placeholder-shown) ~ label {
  background: #fdfdff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.signup-form .form-floating select:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.otp-div {
  position: relative;
}

.otp-div .otp {
  overflow: hidden !important;
}

.otp-div .otp input {
  width: 48px !important;
  height: 48px;
  background: #ffffff;
  border: 1px solid #cfcdd6;
  border-radius: 15px;
  margin: 0 7px !important;
}

.otp-div .otp input:focus {
  outline: none;
  border: 2px solid #dbbb51 !important;
}

.timer {
  /* 0:45 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #7c7c7e;
  margin-top: 20px;
  margin-left: 10px;
}

.timer p {
  margin: 0;
}

.forgot-password a {
  text-decoration: none;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #979797;
}

a {
  text-decoration: none;
}

.camera-div {
  position: absolute;
  top: 250px;
  /* bottom: 0; */
  left: 70px;
  /* bottom: 55px; */
  width: 32px;
  background-color: #fdfdff;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #f6f6fa;
  cursor: pointer;
}

.camera-div label {
  cursor: pointer;
}

.footer-bottom-hr {
  height: 1px;
  margin: 0;
  margin: 0;
  margin-top: 50px;
  background: #dcdcdc;
  margin-bottom: 34px;
}

.footer-copyrights {
  /* © 2022 Afrotierre. All Rights Reserved */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #555555;
}

.footer-copyrights b {
  font-weight: 700;
  color: black;
}

.social-links-container {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: #dbbb51;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 8px;
  cursor: pointer;
}

.social-links-container:hover, .social-links-container:focus {
  background: #000000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.social-links-container:hover path, .social-links-container:focus path {
  fill: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.social-login {
  margin-top: 25px;
}

.social-login .social-login-text {
  /* or sign in with social accounts */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0038em;
  color: #707581;
}

.social-login .apple-btn {
  height: 42px;
  border: 1px solid #000000;
  /* Continue with Apple */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  background: transparent;
  width: 100%;
  border-radius: 30px;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #000000;
}

.social-login .apple-btn span {
  padding-left: 10px;
}

.social-login .apple-btn:focus {
  background: none !important;
  color: #000000;
}

.social-login .google-btn {
  height: 42px;
  border: 1px solid #d9d9d9;
  font-family: "Ageo", sans-serif;
  /* Continue with Google */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  background: transparent;
  width: 100%;
  border-radius: 30px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.54);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.social-login .google-btn span {
  padding-left: 10px;
}

.social-login .google-btn:focus {
  background: none !important;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid #d9d9d9;
}

.social-login .fb-btn {
  height: 42px;
  border: 1px solid #1877f2;
  background: transparent;
  border-radius: 30px;
  width: 100%;
  /* Continue with Facebook */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1877f2;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.social-login .fb-btn span {
  padding-left: 10px;
}

.social-login .fb-btn:focus {
  background: none !important;
  color: #1877f2;
  border: 1px solid #1877f2;
}

button:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.privacy {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.privacy span {
  font-weight: 700;
  color: black;
  text-transform: uppercase;
}

.pagination {
  margin-bottom: 50px;
}

.pagination .page-item:last-child .page-link {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.pagination .page-item:first-child .page-link {
  border-radius: 24px;
  width: 48px !important;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pagination a.page-link {
  margin-left: 10px !important;
  border-radius: 24px;
  background: #f0f0f6;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8888a2;
  width: 48px !important;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none !important;
}

.pagination a.page-link:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: none !important;
}

.pagination .active > .page-link, .pagination .page-link.active {
  background: #000000;
  color: #dbbb51;
  border: 1px solid #000000;
}

.home-responsive-swiper .swiper {
  background: #FDFDFF !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.home-responsive-swiper .swiper-slide img {
  height: 280px;
  border-radius: 16px;
}

.multi-image-uploader input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}

.multi-image-uploader .form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
  background: black;
  width: 130px !important;
  height: 110px;
  background: #DBBB51;
  border-radius: 20px;
}

.multi-image-uploader .swiper-slide.swiper-slide-active {
  width: 110px !important;
}

.multi-image-uploader .swiper-slide.swiper-slide-next {
  width: 110px !important;
}

.multi-image-uploader .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.multi-image-uploader .box {
  width: 110px;
  height: 110px;
  margin-right: 20px;
}

.multi-image-uploader .box img {
  border-radius: 20px;
}

.dashboard-sidebar {
  /* elm/card/main */
  background: #ffffff;
  border-radius: 14px;
  width: 100%;
  height: 774px;
  padding-left: 20px;
}

.dashboard-sidebar .logo-container {
  padding-top: 45px;
  padding-bottom: 30px;
}

.dashboard-sidebar ul {
  text-decoration: none;
  list-style: none;
  padding-left: 0;
}

.dashboard-sidebar ul li {
  text-decoration: none;
  color: #899ca8;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  position: relative;
}

.dashboard-sidebar ul li a {
  text-decoration: none;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #707581;
  line-height: 17px;
  cursor: pointer;
  min-height: 44px;
  /* identical to box height */
  /* 3 */
}

.dashboard-sidebar ul li a .nav-bg {
  padding-left: 12px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dashboard-sidebar ul li a .settings-icon {
  padding-right: 10px;
}

.dashboard-sidebar ul li a.active {
  color: #dbbb51;
  font-weight: 700;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.dashboard-sidebar ul li a.active .nav-bg {
  background-color: #fbf8ee;
  width: 100%;
  margin-right: 20px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 20px;
  padding-left: 12px;
}

.dashboard-sidebar ul li a.active path {
  fill: #dbbb51;
}

.dashboard-sidebar ul li .non-active {
  text-decoration: none;
  color: #899ca8 !important;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  cursor: pointer;
  min-width: 182px;
  min-height: 44px;
  background: transparent !important;
}

.dashboard-sidebar ul li .non-active path {
  fill: #899ca8 !important;
}

.dashboard-sidebar ul li .non-active ::after {
  display: none !important;
}

.projectids .fashionbold {
  /* PN0001265 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.projectids .subid {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-left: 20px;
}

.projectids .subsubid {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-left: 30px;
  text-align: left;
}

.MuiDataGrid-row {
  background: #FCFCFC;
}

.projectids:has(.fashionbold) .MuiDataGrid-row {
  background: red;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
  overflow: hidden;
  white-space: nowrap;
  outline: none !important;
}

.dashboard-layout {
  padding-top: 15px;
  background: #F5F5F5;
  min-height: 100%;
  padding-bottom: 30px;
}

.dashboard-layout::before {
  position: absolute;
  content: '';
  background: #F5F5F5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.dashboard-layout .container {
  max-width: 1400px;
}

.top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.top-bar .search-box input {
  background: white;
  border: none;
  width: 354px;
  height: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid white !important;
  border-left: none;
  /* Search */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7d8592;
}

.top-bar .search-box input:focus {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.top-bar .search-box span#basic-addon1 {
  background: white;
  border: 1px solid white;
  border-right: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 15px;
  cursor: pointer;
}

.top-bar .search-box span#basic-addon1 :hover path {
  fill: #dbbb51;
}

.top-bar .notification-box {
  background: white;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.top-bar .notification-box:hover {
  background-color: #F7F4EB;
}

.top-bar .notification-box:hover path {
  fill: #000000;
}

.top-bar .profile-dropdown {
  width: 162px;
  height: 40px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
}

.top-bar .profile-dropdown:hover {
  background: #F7F4EB;
}

.top-bar .profile-dropdown:has(:focus) {
  background: #dbbb51;
}

.top-bar .profile-dropdown .profile-title {
  /* John Smith */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.top-bar .profile-dropdown .manage-profile {
  /* Manage Profile */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #707581;
}

@media (max-width: 576px) {
  .top-bar .search-box input {
    width: 200px;
  }
}

.product-slider {
  background: #F5F5F5 !important;
}

.product-slider .swiper-testing .mySwiper {
  height: 340px;
  width: 100%;
  padding-left: 24px !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0;
  background: white !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0;
}

.product-slider .swiper-testing .mySwiper img {
  width: 57px;
  height: 57px;
  border-radius: 8px;
}

.product-slider .swiper-testing .mySwiper .swiper.swiper-initialized.swiper-vertical.swiper-pointer-events.swiper-free-mode.swiper-watch-progress.mySwiper.swiper-thumbs {
  padding-left: 25px;
}

.product-slider .swiper {
  width: 100%;
  height: 100%;
}

.product-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #F5F5F5 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
}

.product-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.product-slider .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  background: #F5F5F5 !important;
}

.product-slider .swiper-slide {
  background-size: cover;
  background-position: center;
  background: #F5F5F5 !important;
}

.product-slider .mySwiper2 {
  height: 80%;
  width: 100%;
  background: #F5F5F5 !important;
}

.product-slider .mySwiper {
  height: 20%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0;
  background: #F5F5F5 !important;
}

.product-slider .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.product-slider .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.product-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.product-slider .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1 !important;
}

.product-bg {
  background: white;
  padding: 20px;
  border-radius: 14px;
  min-height: 560px;
}

.product-bg .product-data .product-title {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  color: #000000;
  padding-right: 15px;
}

.product-bg .product-data .edit-product-btn {
  background: black;
  width: 160px;
  height: 40px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  color: #dbbb51;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 20px;
}

.product-bg .product-data .plus-btn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid black;
  margin-left: 10px;
  cursor: pointer;
}

.product-bg .product-data .product-discount-price {
  /* $120.20 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  /* Black */
  color: #000000;
}

.product-bg .product-data .product-original-price {
  /* $150.45 */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #707581;
  padding-left: 15px;
}

.product-bg .product-data .review-section {
  margin-top: 15px;
}

.product-bg .product-data .rating-reviews {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  padding-left: 10px;
}

.product-bg .product-data .tags-section {
  margin-top: 15px;
}

.product-bg .product-data .tags-section :last-child {
  margin-right: 0;
}

.product-bg .product-data .tags {
  background: #f5e7bd;
  min-width: 62px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Women */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #3a3b3b;
  border-radius: 8px;
  margin-right: 10px;
  padding: 0 11px;
}

.product-bg .product-data .made-in-section {
  width: 343px;
  height: 60px;
  background: #f6f6fa;
  border-radius: 10px;
  margin-top: 15px;
  padding: 0 10px;
}

.product-bg .product-data .made-in-section .heading {
  /* Made In */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #a7a7a8;
}

.product-bg .product-data .made-in-section .seperator-line {
  height: 40px;
  border-left: 0.5px solid #ceced4;
}

.product-bg .product-data .made-in-section .no-of-orders {
  /* 360 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.product-bg .product-data .buyer-product-rating {
  /* 4.0 (258) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.product-bg .product-data .reviews-num {
  color: #707581;
  padding-left: 5px;
}

.product-bg .product-data .product-description .description-heading {
  /* Description: */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 20px;
}

.product-bg .product-data .product-description ul {
  margin-top: 10px;
  padding-left: 20px;
  max-width: 545px;
}

.product-bg .product-data .product-description ul li {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #707581;
}

.product-bg .product-data .productinfo .other-info {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #000000;
}

.product-bg .product-data .productinfo ul {
  padding-left: 20px;
}

.product-bg .product-data .productinfo ul li {
  font-size: 14px;
  font-weight: 500;
  padding-top: 11px;
  font-family: "Ageo", sans-serif;
  color: #707581;
}

.product-bg .product-data .productinfo ul li span {
  color: black;
}

.product-slider {
  background: white !important;
}

.product-table-data {
  margin-left: 10px;
}

.product-table-data .product-name {
  width: 186px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  text-overflow: ellipsis;
  overflow: hidden;
}

.product-table-data .product-no {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #707581;
  margin-top: 3px;
}

.product-management-rating .productstarrating {
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  margin-left: 5px;
  color: #3a3b3b !important;
}

.product-management-rating .productstarsubrating {
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  margin-left: 2px;
  color: #3a3b3b !important;
}

.rating-and-review-section {
  background: #ffffff;
  border-radius: 14px;
  margin-top: 20px;
  padding: 20px;
}

.rating-and-review-section .rating-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: black;
  margin-bottom: 8px;
}

.rating-and-review-section .star-reviews {
  /* 4.9 (2130 reviews) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  padding-left: 10px;
  margin-top: 7px;
}

.rating-and-review-section hr {
  height: 1px;
  /* Light Grey-Separator */
  color: #adadad;
  margin: 0;
  margin-top: 20px;
  width: 90%;
}

.review-card {
  margin-top: 20px;
}

.review-card .review-author {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.38px;
  color: #000000;
}

.review-card .review-date {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #707581;
  padding-left: 10px;
}

.review-card .rating {
  /* 4.0 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  padding-left: 4px;
}

.review-card .review {
  /* Reviews */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #707581;
  max-width: 654px;
  margin-top: 10px;
}

.review-card .review-card-details {
  padding-left: 11px;
}

.orderno-bg {
  background: white;
  width: 100%;
  height: 356px;
  border-radius: 14px;
  padding: 20px;
}

.orderno-bg h2 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #121212;
  margin-bottom: 5px;
}

.orderno-bg .order-no {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #dbbb51;
}

.orderno-bg .order-data {
  margin-top: 15px;
}

.orderno-bg .order-data .order-ques {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.orderno-bg .order-data .order-ans {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.order-detail-table {
  background: #ffffff;
  border-radius: 14px;
  height: 506px;
  padding: 20px;
}

.reject-btn {
  width: 160px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  /* Black */
  color: #000000;
  background: transparent;
  border-radius: 20px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #000000;
  margin-top: 20px;
}

.reject-btn:hover, .reject-btn:focus {
  background: #dbbb51 !important;
  color: #000000 !important;
  border: 1px solid #dbbb51 !important;
}

.accept-btn {
  width: 160px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  /* Black */
  color: #dbbb51;
  background: #000000;
  border-radius: 20px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #000000;
  margin-left: 20px;
  margin-top: 20px;
}

.accept-btn:hover, .accept-btn:focus {
  background: #dbbb51 !important;
  color: #000000 !important;
  border: 1px solid #dbbb51 !important;
}

.table-search-box .input-group
> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
.valid-feedback
):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.table-search-box input {
  background: #fbfbfd;
  border: none;
  width: 334px !important;
  height: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid white !important;
  border-left: none;
  /* Search */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7d8592;
}

.table-search-box input:focus {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  background: #fbfbfd;
}

.table-search-box span#basic-addon1 {
  background: #fbfbfd;
  border: 1px solid white;
  border-right: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 15px;
  cursor: pointer;
}

.table-search-box span#basic-addon1 :hover path {
  fill: #dbbb51;
}

.table-right-btn {
  width: 160px;
  height: 40px;
  background: #000000;
  color: #dbbb51;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Button */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-radius: 20px;
  border: 1px solid #000000;
  margin-left: 10px;
}

.table-right-btn:focus, .table-right-btn:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #000000;
  background: #000000 !important;
  color: #dbbb51;
}

.order-dropdown .css-qbdosj-Input {
  width: 100px;
}

.order-dropdown .css-b62m3t-container {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 160px !important;
  height: 40px !important;
}

.category-parent-dropdown {
  margin: 20px 0;
}

.category-parent-dropdown .css-qbdosj-Input {
  height: 33px;
  font-family: "Ageo", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581 !important;
}

.category-parent-dropdown .parent-opt {
  /* Parent */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #707581;
}

.category-img-div {
  position: relative;
  width: 89px;
  height: 89px;
  border-radius: 14px;
  margin-bottom: 24px;
}

.category-img-div img {
  border-radius: 14px;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.category-img-div .file-upload {
  width: 90px;
  height: 90px;
  border-radius: 14px;
  overflow: hidden;
}

.table-content-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.table-content-center .css-17jjc08-MuiDataGrid-footerContainer {
  display: none !important;
}

.table-content-center .MuiDataGrid-footerContainer.css-1yy0dv2 {
  display: none !important;
}

.table-content-center .MuiDataGrid-footerContainer {
  display: none !important;
}

.inbox-section {
  background: #ffffff;
  border-radius: 14px;
  height: 647px;
  padding: 20px;
}

.inbox-section .search-box input {
  background: #fbfbfd;
  border: none;
  max-width: 354px;
  height: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid white !important;
  border-left: none;
  /* Search */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7d8592;
}

.inbox-section .search-box input:focus {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.inbox-section .search-box span#basic-addon1 {
  background: #fbfbfd;
  border: 1px solid white;
  border-right: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 15px;
  cursor: pointer;
}

.inbox-section .search-box span#basic-addon1 :hover path {
  fill: #dbbb51;
}

.inbox-section .no-msg {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  text-align: center;
  letter-spacing: 0.15px;
  /* Grey */
  color: #707581;
  display: block;
}

.messages-section {
  background: #ffffff;
  border-radius: 14px;
  height: 647px;
  padding: 30px 20px;
  position: relative;
}

.messages-section .author-name {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.messages-section hr {
  height: 1px;
  border: 1px solid #c9c9c9a4;
  margin: 0;
  margin-top: 30px;
}

.messages-section textarea {
  border: 1px solid #ceced4;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ceced4;
  width: 581px;
  min-height: 43px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #707581;
  margin: 0 10px;
  resize: none;
  padding-top: 18px !important;
  padding-left: 10px !important;
}

.messages-section textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #707581;
  padding-top: 18px !important;
  padding-left: 10px !important;
}

.messages-section textarea:focus ::-webkit-input-placeholder {
  display: none;
}

.messages-section textarea:focus :-ms-input-placeholder {
  display: none;
}

.messages-section textarea:focus ::-ms-input-placeholder {
  display: none;
}

.messages-section textarea:focus ::placeholder {
  display: none;
}

.messages-section textarea::-webkit-scrollbar {
  display: none;
}

.messages-section textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.messages-section textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.messages-section textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.messages-section textarea:focus::placeholder {
  color: transparent;
}

.messages-section .messages-area {
  height: 470px;
}

.messages-section .attachment-bg {
  background: #fbfbfd;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 43px;
  height: 43px;
  cursor: pointer;
  border-radius: 10px;
}

.messages-section .send-bg {
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: black;
  border-radius: 10px;
  cursor: pointer;
}

.messages-section .message-position {
  position: absolute;
  bottom: 30px;
}

.settings-sidebar {
  height: 652px;
  background: #ffffff;
  border-radius: 14px;
  padding: 30px 20px;
}

.settings-sidebar ul {
  margin: 0;
  padding: 0;
}

.settings-sidebar ul li {
  list-style: none;
}

.settings-sidebar ul li a {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #707581;
}

.settings-sidebar ul li a.active {
  font-weight: 600;
  color: #dbbb51;
}

.settings-sidebar ul li a.active path {
  fill: #dbbb51;
}

.settings-sidebar hr {
  width: 100%;
  height: 1px;
  background: #ebebf9;
  margin: 0;
  padding: 0;
  margin: 20px 0;
}

.settings-main-section {
  height: 652px;
  background: #ffffff;
  border-radius: 14px;
  padding: 20px;
}

.settings-main-section .settings-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */
  /* Black */
  color: #000000;
  padding-bottom: 30px;
}

.form-switch .form-check-input {
  height: 31px;
  width: 51px;
}

.form-check-input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #dbbb51;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-color: #dbbb51;
}

.push-notification-text {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #707581;
}

.change-password-page form .iconfeild {
  background-color: #ffffff;
}

.change-password-page form .form-floating label {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding: 13px 16px;
}

.change-password-page form .form-floating label:focus {
  position: absolute;
  top: -10px !important;
}

.change-password-page form .form-floating input {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 35px;
  padding: 13px 16px !important;
}

.change-password-page form .form-floating input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.change-password-page form .form-floating input:focus label {
  color: blue !important;
}

.change-password-page form .form-floating input:not(:placeholder-shown) ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.change-password-page form .form-floating input:focus ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.change-password-page form .form-floating textarea {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  height: 100px;
  resize: none;
}

.change-password-page form .form-floating textarea::-webkit-scrollbar {
  display: none;
}

.change-password-page form .form-floating textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.change-password-page form .form-floating textarea:focus label {
  color: blue !important;
}

.change-password-page form .form-floating textarea:not(:placeholder-shown) ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.change-password-page form .form-floating textarea:focus ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.change-password-page form .form-floating select {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  resize: none;
}

.change-password-page form .form-floating select::-webkit-scrollbar {
  display: none;
}

.change-password-page form .form-floating select:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.change-password-page form .form-floating select:focus label {
  color: blue !important;
}

.change-password-page form .form-floating select:not(:placeholder-shown) ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.change-password-page form .form-floating select:focus ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.change-password-page form .form-group {
  margin-bottom: 20px;
}

.change-password-page form .form-label {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
  background-color: transparent;
}

.change-password-page form .form-control {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
  background: transparent;
  border: 1px solid #ceced4;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
}

.change-password-page form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.change-password-page form .form-control::-webkit-input-placeholder {
  display: none;
}

.change-password-page form .form-control:-ms-input-placeholder {
  display: none;
}

.change-password-page form .form-control::-ms-input-placeholder {
  display: none;
}

.change-password-page form .form-control::placeholder {
  display: none;
}

.change-password-page form .form-select {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background-color: transparent;
  border: 1px solid #ebebf9;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  background-image: url("../images/BuyerProfile/drop-down-arrow.png");
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  display: block;
  width: 100%;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
}

.change-password-page form .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.change-password-page form .button-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.change-password-page form .button-div button {
  margin-top: 10px;
  margin-bottom: 40px;
  background: #000000;
  border-radius: 20px;
  height: 48px;
  max-width: 200px;
  width: 100%;
  color: #dbbb51;
  text-transform: capitalize;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border: none;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.change-password-page form .button-div button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.change-password-page form .button-div button:hover {
  background-color: #dbbb51;
  color: #ffffff;
}

.change-password-page h3 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 30px;
}

.change-password-page .password-input input {
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  border-right: none;
}

.change-password-page .password-input input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-right: none;
}

.change-password-page .password-input span#basic-addon2 {
  background: white;
  border: 1px solid #ceced4;
  border-left: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.change-password-page .reset-pass-btn {
  width: 160px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #000000;
  /* Button */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #dbbb51;
  text-transform: capitalize;
  border-radius: 20px;
  border: 1px solid #000000;
  margin-top: 20px;
}

.change-password-page .reset-pass-btn:focus {
  background: #dbbb51;
  color: #000000;
  border: 1px solid #dbbb51;
}

.terms-and-conditions p {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #121212;
}

.delete-account .reason-for-deleting {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 30px;
}

.delete-account select.form-select {
  height: 43px;
  border-radius: 30px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.delete-account select.form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #dbbb51;
}

.delete-account textarea {
  margin-top: 20px;
  border: 1px solid #ceced4;
  border-radius: 14px;
  padding: 12px 16px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.delete-account textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.delete-account .submit-btn {
  width: 160px;
  height: 40px;
  background: #000000;
  color: #dbbb51;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
  border: 1px solid #000000;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
}

.contactus-page .query {
  /* Let us know about your query.. */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 30px;
}

.PhoneInputInput {
  border: none;
  min-width: 100%;
  border-left: 2px solid #bbbfcc;
  padding-left: 10px;
  margin-right: 20px;
  /* Phone Number */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.PhoneInputInput:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.PhoneInput {
  border: 1px solid #707581;
  height: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
  padding-left: 16px;
  border: 1px solid #d4d4d6;
  border-radius: 20px;
  margin-bottom: 20px;
}

.PhoneInput:has(:focus) {
  border: 1px solid #dbbb51;
}

.ps_12 {
  padding-left: 12px;
}

.my-profile .profile-data {
  height: 652px;
  background: #ffffff;
  border-radius: 14px;
  padding: 30px;
}

.my-profile .profile-data .profile-title {
  /* The Half Moon */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  margin-top: 20px;
}

.my-profile .profile-data .profile-occupation {
  /* UI/UX Designer */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5c5c5c;
  margin-top: 4px;
}

.my-profile .profile-data .profile-mail {
  /* UI/UX Designer */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5c5c5c;
  margin-top: 2px;
}

.my-profile .profile-data .image-container {
  width: 100px;
  height: 100px;
  border-radius: 75px;
}

.my-profile .profile-data .image-container img {
  -o-object-fit: cover;
     object-fit: cover;
}

.my-profile .profile-form {
  height: 652px;
  background: #ffffff;
  border-radius: 14px;
  padding: 30px;
}

.my-profile .profile-form .update-btn {
  width: 160px;
  height: 40px;
  margin-top: 20px;
  background: #000000;
  color: #dbbb51;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: capitalize;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px;
  border: 1px solid #000000;
}

.my-profile .image-container {
  width: 100px;
  height: 100px;
  border-radius: 75px;
}

.my-profile .image-container img {
  -o-object-fit: cover;
     object-fit: cover;
}

.contact-update-btn {
  width: 160px;
  height: 40px;
  margin-top: 20px;
  background: #000000;
  color: #dbbb51;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: capitalize;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px;
  border: 1px solid #000000;
}

.myprofile-popup {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
          box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
}

.profile-modal .modal-content {
  width: 337px;
  height: 380px;
  background: white;
  padding: 30px 20px;
}

.profile-modal .modal-dialog {
  position: fixed;
  right: 380px;
  top: 40px;
}

.profile-modal .modal-title {
  /* My Profile */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.profile-modal .cross-bg {
  background: #f3f3f3;
  border-radius: 14px;
  height: 44px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.profile-modal .image-container {
  width: 100px;
  height: 100px;
  border-radius: 75px;
  margin-right: 15px;
}

.profile-modal .image-container img {
  -o-object-fit: cover;
     object-fit: cover;
}

.profile-modal .profile-title {
  /* The Half Moon */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.profile-modal .profile-title a {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.profile-modal .profile-occupation {
  /* UI/UX Designer */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5c5c5c;
  margin-top: 4px;
}

.profile-modal .profile-occupation a {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5c5c5c;
  margin-top: 4px;
}

.profile-modal .profile-mail {
  /* UI/UX Designer */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5c5c5c;
  margin-top: 4px;
}

.profile-modal .profile-mail a {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5c5c5c;
  margin-top: 4px;
}

.profile-modal hr {
  height: 1px;
  background: #f7f7f7;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.profile-modal .profile-links {
  /* Edit Profile */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #707581;
}

.nav-hover:hover .profile-links {
  color: #dbbb51;
}

.nav-hover:hover path {
  fill: #dbbb51;
}

.logout-hover:hover .profile-links {
  color: red;
  cursor: pointer;
}

.search-bg {
  /* elm/card/main */
  background: #ffffff;
  border-radius: 14px;
  height: 709px;
}

.search-text {
  /* There are no search results yet. */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #707581;
  margin-top: 16px;
}

.noproduct-text {
  /* There are not products here yet. */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #707581;
  margin: 16px 0;
}

.add-product-btn {
  width: 160px;
  height: 40px;
  background: #000000;
  color: #dbbb51;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #000000;
  border-radius: 20px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #dbbb51;
}

.add-product-btn:hover, .add-product-btn:focus {
  background: #4e4e4e !important;
  color: #dbbb51 !important;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.notification-modal .modal-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  padding: 10px !important;
}

.notification-modal .modal-content {
  border-radius: 15px;
  width: 337px;
  padding: 30px 20px;
}

.notification-modal .modal-dialog {
  margin-right: 30px;
  margin-top: 90px;
}

.notification-modal .modal-body .header h6 {
  font-weight: 500;
}

.notification-modal .modal-body .header h6 span {
  font-size: 18px;
  font-weight: 500;
}

.notification-modal .modal-body .header .btns-time {
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.notification-modal .modal-body .notification-container {
  height: calc(100vh - 235px);
  overflow-y: scroll;
}

.notification-modal .modal-body .notification-container::-webkit-scrollbar {
  display: none;
}

.notification-popup .notification-heading {
  /* Notifications */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.notification-popup .cross-bg {
  background: #f3f3f3;
  border-radius: 14px;
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.notification-popup .main-notification-panel {
  height: 400px;
  overflow: auto;
}

.notification-popup .main-notification-panel::-webkit-scrollbar {
  width: 0;
}

.notification-popup .main-notification-panel .main-seen-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #F7F7F7;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.notification-popup .main-notification-panel .main-seen-clear .main-seen-div span {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.notification-popup .main-notification-panel .main-seen-clear .main-clear-div button {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #989898;
  background-color: transparent;
  border: none;
}

.notification-popup .notification-box .unseen {
  background: #dbbb51;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.notification-popup .notification-box .seen {
  background: white;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.notification-popup .notification-box .order-status {
  /* Order Completed */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.notification-popup .notification-box .order-notif {
  /* Order #23456 has been delivered successfully */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707581;
  margin-top: 4px;
}

.notification-popup .notification-box .notif-delivery-time {
  /* 4 hours ago */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707581;
  margin-top: 6px;
}

.notification-popup .notification-box hr {
  width: 100%;
  height: 1px;
  background: #f6f6f6;
  margin: 0;
  margin: 15px 0;
}

.notification-popup .view-all {
  /* View All (20) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #dbbb51;
}

.delete-modal .modal-content {
  max-width: 431px;
  height: 225px;
  border-radius: 10px;
  background: white;
  padding: 32px;
  margin: 0 auto;
}

.delete-modal .main-heading {
  /* Info */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  color: #000000;
}

.delete-modal p {
  margin-top: 32px;
  margin-bottom: 0 !important;
  /* Content */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  /* Grey */
  color: #707581;
}

.delete-modal .main-btn {
  width: 160px;
  height: 40px;
  background: #000000;
  color: #dbbb51;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  border-radius: 20px;
  border: 1px solid #000000;
  cursor: pointer;
}

.delete-modal .main-btn:focus, .delete-modal .main-btn:hover {
  background: #000000 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #000000;
  color: #dbbb51 !important;
}

.delete-modal .sub-btn {
  width: 100px;
  height: 40px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  /* Button */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: capitalize;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Black */
  color: #000000;
}

.delete-modal .sub-btn:focus, .delete-modal .sub-btn:hover {
  background: transparent !important;
  /* Black */
  color: #000000 !important;
  border: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
}

.edit-product-modal .modal-content {
  max-width: 835px;
  min-height: 772px;
  background: white;
  border-radius: 14px;
  padding: 40px 30px;
}

.edit-product-modal .main-heading {
  /* Add Product */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.edit-product-modal .cross-bg {
  background: #f3f3f3;
  border-radius: 14px;
  height: 44px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.edit-product-modal .modal-form {
  height: 567px;
  overflow-y: scroll;
  margin-top: 20px;
  padding-top: 10px;
}

.edit-product-modal .modal-form::-webkit-scrollbar {
  display: none;
}

.edit-product-modal .modal-form select.form-select {
  height: 43px;
  border-radius: 30px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.edit-product-modal .modal-form select.form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #dbbb51;
}

.edit-product-modal .modal-form select.form-select .dropdown-content input[type="checkbox"] {
  background: #dbbb51 !important;
}

.edit-product-modal .modal-form textarea {
  border: 1px solid #ceced4;
  border-radius: 14px;
  padding: 12px 16px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  margin-bottom: 20px;
}

.edit-product-modal .modal-form textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.edit-product-modal .modal-form .rmsc .dropdown-container {
  --rmsc-main: $base-yellow;
  --rmsc-hover: $base-yellow;
  --rmsc-selected: $base-yellow;
  border-radius: 20px;
  height: 43px;
  border: 1px solid #ceced4;
  margin-left: 2px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.edit-product-modal .modal-form .rmsc .dropdown-container.active, .edit-product-modal .modal-form .rmsc .dropdown-container:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51 !important;
}

.report-chat .modal-content {
  width: 431px;
  height: 342px;
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
          box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
  margin: 0 auto;
  padding: 30px;
}

.report-chat .modal-title {
  /* Report Chat */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.report-chat .cross-bg {
  background: #f3f3f3;
  border-radius: 14px;
  height: 44px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.report-chat .report-conditions {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #707581;
  padding-left: 10px;
}

.report-chat input[type="radio"]:checked + label {
  background-color: white !important;
}

.report-chat input[type="radio"]:checked {
  background-color: red !important;
}

.report-chat [type="radio"]:checked,
.report-chat [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.report-chat [type="radio"]:checked + label,
.report-chat [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #707581;
}

.report-chat [type="radio"]:checked + label:before,
.report-chat [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.report-chat [type="radio"]:checked + label:after,
.report-chat [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #dbbb51;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.report-chat [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.report-chat [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.report-chat .main-btn {
  width: 160px;
  height: 40px;
  background: #000000;
  color: #dbbb51;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  border-radius: 20px;
  border: 1px solid #000000;
  cursor: pointer;
}

.report-chat .main-btn:focus, .report-chat .main-btn:hover {
  background: #000000 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #000000;
  color: #dbbb51 !important;
}

.report-chat .sub-btn {
  width: 100px;
  height: 40px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  /* Button */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: capitalize;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Black */
  color: #000000;
}

.report-chat .sub-btn:focus, .report-chat .sub-btn:hover {
  background: transparent !important;
  /* Black */
  color: #000000 !important;
  border: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
}

.category-btn-offcanvas {
  width: 504px !important;
  background: #f6f6fa !important;
  padding: 32px 54px 32px 54px;
}

.category-btn-offcanvas ::-webkit-scrollbar {
  display: none;
}

.category-btn-offcanvas .category-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #323252;
}

.category-btn-offcanvas .boxes-section {
  margin-top: 45px;
}

.category-btn-offcanvas .boxes-section .category-boxes {
  background: #fdfdff;
  border: 1px solid #e3e3f1;
  border-radius: 14px;
  /* Daily Deals */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #020203;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 16px;
  width: 368px;
  height: 48px;
  margin-bottom: 16px;
  cursor: pointer;
}

.category-btn-offcanvas .cross-btn {
  background: #77778d;
}

.product-listing-banner {
  background-image: url("/src/assets/images/Home/productlisting.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 414px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 24px;
}

.product-listing-banner .banner-container {
  padding-left: 48px;
}

.product-listing-banner .banner-container .banner-title {
  /* Everything for your Home */
  width: 346px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 16px;
}

.product-listing-banner .banner-container .banner-desc {
  /* New arrivals from handmade masters, Ceramic and aluminum in bright yellow */
  max-width: 346px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
  margin-bottom: 24px;
}

.product-listing-banner .banner-container .banner-button {
  width: 200px;
  height: 40px;
  border-radius: 100px;
  background: #fdfdff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #fdfdff;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.product-listing-banner .banner-container .banner-button:focus, .product-listing-banner .banner-container .banner-button:hover {
  background: #fdfdff !important;
  color: #000000 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
  border: 1px solid #fdfdff;
}

.product-listing-section .section-heading {
  /* Shop */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 16px;
  margin-top: 64px;
}

.product-listing-section .filter-btn {
  background: #fdfdff;
  height: 40px;
  width: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* All Filters */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  border: 1px solid #e3e3f1;
  border-radius: 26px;
}

.product-listing-section button#filter-dropdown {
  background: #fdfdff;
  font-family: "Ageo", sans-serif;
  /* Sort by: Default sorting */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  height: 40px;
  width: 208px;
  border: 1px solid #e3e3f1;
  border-radius: 26px;
}

.product-listing-section button#filter-dropdown:focus, .product-listing-section button#filter-dropdown:hover {
  background: white !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.product-listing-section .view {
  /* View: */
  padding-left: 15px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.product-listing-section .view-btn {
  border: 1px solid #e3e3f1;
  width: 48px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fdfdff;
  border-radius: 24px;
  margin-left: 8px;
}

.product-listing-section .view-btn:focus, .product-listing-section .view-btn:hover {
  background: #dbbb51;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e3e3f1;
}

.filter-btn-offcanvas {
  width: 545px !important;
  background: #f6f6fa !important;
  padding: 12px 0;
  height: 100vh;
}

.filter-btn-offcanvas ::-webkit-scrollbar {
  display: none;
}

.filter-btn-offcanvas .filter-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #323252;
  margin-left: 87px;
}

.filter-btn-offcanvas .filter-conditions-section {
  padding-left: 47px;
}

.filter-btn-offcanvas .filter-conditions-section .subheading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 22px;
}

.filter-btn-offcanvas .filter-conditions-section hr {
  margin: 0;
  height: 1px;
  background: #e3e3f1;
  margin-top: 16px;
}

.filter-btn-offcanvas .filter-conditions-section input[type="radio"]:checked + label {
  background-color: transparent !important;
}

.filter-btn-offcanvas .filter-conditions-section label {
  margin-top: 10px;
  margin-left: 8px;
}

.filter-btn-offcanvas .filter-conditions-section input {
  margin-top: 12px;
}

.filter-btn-offcanvas .filter-conditions-section .form-check-input {
  width: 20px;
  height: 20px;
}

.filter-btn-offcanvas .filter-conditions-section .form-check-input:checked {
  background-color: #dbbb51;
  border-color: #dbbb51;
}

.filter-btn-offcanvas .cross-btn {
  background: #77778d;
}

.filter-btn-offcanvas .origin input {
  width: 232px !important;
}

.filter-btn-offcanvas .custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.filter-btn-offcanvas .custom .to {
  /* To */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 10px 10px 0 10px;
}

.filter-btn-offcanvas .custom input {
  background: #fdfdff;
  height: 40px;
  width: 100px;
  border-radius: 16px;
  border: 1px solid #fdfdff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.filter-btn-offcanvas .custom input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.filter-btn-offcanvas .custom ::-webkit-input-placeholder {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8888a2 !important;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.filter-btn-offcanvas .custom :-ms-input-placeholder {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8888a2 !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.filter-btn-offcanvas .custom ::-ms-input-placeholder {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8888a2 !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.filter-btn-offcanvas .custom ::placeholder {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8888a2 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.filter-btn-offcanvas .custom label {
  margin: 0;
  background: #f6f6fa !important;
}

.filter-btn-offcanvas .btn-pos {
  margin-top: 80px;
}

.filter-btn-offcanvas .apply-btn {
  background: #000000;
  width: 200px;
  height: 48px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fdfdff;
  border-radius: 100px;
  border: 1px solid #000000;
  margin-left: 20px;
}

.filter-btn-offcanvas .apply-btn:focus, .filter-btn-offcanvas .apply-btn:hover {
  background: #444444;
  color: #fdfdff;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #000000;
}

.filter-btn-offcanvas .cancel-btn {
  background: transparent;
  border: 1px solid #000000;
  color: #000000;
  width: 200px;
  height: 48px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-radius: 100px;
}

.filter-btn-offcanvas .cancel-btn:focus, .filter-btn-offcanvas .cancel-btn:hover {
  background: #fdfdff;
  color: #000000;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #000000;
}

.filter-btn-offcanvas.offcanvas.offcanvas-start.show {
  width: 450px !important;
}

.buyer-product-details .side-border {
  border-left: 1px solid #e3e3f1;
  padding-left: 30px;
}

.buyer-product-details .side-border .buyer-product-title {
  /* Black Minimalist Wall Clock */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.buyer-product-details .side-border .size {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #7c7c7e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.buyer-product-details .side-border .sizes-btns .form-check {
  padding: 0;
  margin-right: 15px;
}

.buyer-product-details .side-border .sizes-btns .form-check-input {
  display: none;
}

.buyer-product-details .side-border .sizes-btns form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.buyer-product-details .side-border .sizes-btns label {
  height: 40px;
  width: 40px;
  border: 1px solid #e3e3f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}

.buyer-product-details .side-border .sizes-btns input[type="radio"]:checked + label {
  border: 1px solid #dbbb51;
  background: transparent;
}

.buyer-product-details .side-border .color-btns .form-check {
  padding: 0;
  margin-right: 15px;
}

.buyer-product-details .side-border .color-btns .form-check-input {
  display: none;
}

.buyer-product-details .side-border .color-btns form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.buyer-product-details .side-border .color-btns label {
  border: 1px solid #e3e3f1;
  height: 27px;
  width: 27px;
  border-radius: 20px;
}

.buyer-product-details .side-border .color-btns input[type="radio"]:checked + label {
  border: 1px solid #000000;
  background: transparent;
  height: 27px;
  width: 27px;
  border-radius: 20px;
}

.buyer-product-details .side-border .color-btn {
  width: 25px;
  height: 25px;
  border-radius: 20px;
}

.buyer-product-details .side-border .stock-btn {
  width: 111px;
  height: 40px;
  border-radius: 8px;
  background: #d7fae9;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #069952;
  border: 1px solid #069952;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.buyer-product-details .side-border .wishlist-btn {
  /* Wishlist */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  background: #fdfdff;
  border: 1px solid #fdfdff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.buyer-product-details .side-border .wishlist-btn .wishlist-icon-container {
  background: #e3e3f1;
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 24px;
}

.buyer-product-details .side-border .wishlist-btn span {
  padding-left: 10px;
}

.buyer-product-details .side-border .added path {
  fill: #dbbb51;
}

.buyer-product-details .side-border .made-in-section {
  width: 343px;
  height: 60px;
  background: #f6f6fa;
  border-radius: 10px;
  margin-top: 15px;
  padding: 0 10px;
}

.buyer-product-details .side-border .made-in-section .heading {
  /* Made In */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #a7a7a8;
}

.buyer-product-details .side-border .made-in-section .seperator-line {
  height: 40px;
  border-left: 0.5px solid #ceced4;
}

.buyer-product-details .side-border .made-in-section .no-of-orders {
  /* 360 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.buyer-product-details .side-border .buyer-product-rating {
  /* 4.0 (258) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.buyer-product-details .side-border .reviews-num {
  color: #707581;
  padding-left: 5px;
}

.buyer-product-details .quantity-section {
  margin-top: 20px;
}

.buyer-product-details .quantity-section .quantity-heading {
  /* Quantity: */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 12px;
}

.buyer-product-details .quantity-section .quantity-btn {
  width: 103px;
  height: 48px;
  border: 1px solid #000000;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 15px;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.buyer-product-details .quantity-section .add-to-cart-btn {
  background: #000000;
  color: #dbbb51;
  border: 1px solid #000000;
  height: 48px;
  width: 220px;
  border-radius: 100px;
}

.buyer-product-details .product-details-accordion .accordion {
  background: #f6f6fa;
  border-radius: 24px;
}

.buyer-product-details .product-details-accordion .accordion-button {
  background: transparent;
}

.buyer-product-details .product-details-accordion .accordion-button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #000000 !important;
}

.buyer-product-details .product-details-accordion .accordion-button:not(.collapsed) {
  color: #000000 !important;
}

.buyer-product-details .product-details-accordion .accordion-button:not(.collapsed) ::after {
  background-image: none !important;
}

.buyer-product-details .product-details-accordion .accordion-item {
  background: transparent !important;
  border: none !important;
  border-radius: 30px;
}

.buyer-product-details .product-details-accordion .acc-heading {
  /* Shipping */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: "Ageo", sans-serif;
}

.buyer-product-details .product-details-accordion .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
  -webkit-transform: var(--bs-accordion-btn-icon-transform);
          transform: var(--bs-accordion-btn-icon-transform);
}

.buyer-product-details .product-details-accordion .conditions {
  /* Shipping Charges: $10 worldwide */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #707581;
  padding-right: 5px;
}

.buyer-product-details .product-details-accordion hr {
  /* Separator */
  margin: 0;
  margin-top: 24px;
  height: 1px;
  background: #e3e3f1;
}

.buyer-product-details .message-seller {
  background: #f6f6fa;
  padding: 14px 22px;
  border-radius: 24px;
  margin-top: 30px;
}

.buyer-product-details .message-seller .halfmoon-title {
  /* The Half Moon */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #7c7c7e;
}

.buyer-product-details .message-seller .halfmoon-title span {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.buyer-product-details .message-seller .seller-btn {
  width: 100%;
  height: 40px;
  background: transparent;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 20px;
  margin-top: 20px;
}

.buyer-product-details .tab-btn-container {
  background: #f6f6fa;
  padding: 12px;
  border-radius: 16px;
}

.buyer-product-details .tab-btn-container a {
  /* Description */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  height: 33px;
  margin-top: 8px;
  background: white;
  color: #000000;
  border-radius: 8px;
}

.buyer-product-details .tab-btn-container a.active {
  background: #fff1c7;
  color: #000000;
}

.buyer-product-details .tabs-heading {
  /* Description */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.buyer-product-details .tabs-para {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.buyer-product-details .more-product-section {
  margin: 30px 0 70px 0;
}

.buyer-product-details .more-product-section .more-heading {
  /* More from this shop */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 16px;
}

.buyer-product-details .more-product-section .see-more {
  /* See more */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 16px;
  cursor: pointer;
}

.buyer-product-listing {
  margin-top: 30px;
  margin-bottom: 70px;
}

.buyer-product-listing .seller-profile-card {
  min-height: 198px;
  background: #f6f6fa;
  padding: 25px;
  border-radius: 24px;
}

.buyer-product-listing .seller-profile-card .seller-img-container {
  border-radius: 75px;
  height: 142px;
  width: 142px;
  -o-object-fit: contain;
     object-fit: contain;
}

.buyer-product-listing .seller-profile-card .seller-profile-title {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 18px;
  color: #000000;
}

.buyer-product-listing .seller-profile-card .seller-location {
  /* Houston, Texas, US */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #707581;
  margin-top: 15px;
  margin-bottom: 20px;
}

.buyer-product-listing .seller-profile-card .no-of-product-sold {
  /* 5,652 product sold */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #7c7c7e;
  border-right: 1px solid #ceced4;
  width: 140px;
  padding-right: 15px;
}

.buyer-product-listing .seller-profile-card .star-reviews {
  /* 4.0 (258) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 8px;
  margin-left: 3px;
}

.buyer-product-listing .seller-profile-card .star-reviews span {
  color: #707581;
}

.buyer-product-listing .seller-profile-card .data-section {
  margin-left: 34px;
}

.buyer-product-listing .seller-profile-card .msg-seller-btn {
  background: transparent;
  border: 1px solid #000000;
  color: #000000;
  width: 175px;
  height: 40px;
  border-radius: 20px;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-transform: capitalize;
  /* Black */
  color: #000000;
}

.buyer-product-listing .all-products-heading {
  /* All Products */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 16px;
}

.buyer-product-listing .tab-btn-container {
  background: #f6f6fa;
  padding: 12px;
  border-radius: 16px;
}

.buyer-product-listing .tab-btn-container a {
  /* Description */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  height: 33px;
  margin-top: 8px;
  background: white;
  color: #000000;
  border-radius: 8px;
}

.buyer-product-listing .tab-btn-container a.active {
  background: #fff1c7;
  color: #000000;
}

.buyer-product-listing .tabs-heading {
  /* Description */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.buyer-product-listing .tabs-para {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.cart-empty-section .empty-msg {
  background: #fff1c7;
  margin-top: 40px;
  border-radius: 32px;
  padding: 22px 30px;
  margin-bottom: 30px;
}

.cart-empty-section .empty-msg .msg-text {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-left: 24px;
}

.cart-empty-section .empty-bag-text {
  /* Your bag is empty */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #77778d;
  text-align: center;
  margin-top: 26px;
}

.cart-empty-section .cart-bag {
  margin-top: 10px;
  margin-bottom: 140px;
}

.cart-screen-section .no-of-items {
  /* 2 Items in your cart */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.cart-screen-section label {
  width: 90%;
}

.cart-screen-section .delete-txt {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f76356;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.cart-screen-section .delete-txt span {
  margin-right: 8px;
}

.cart-screen-section .form-check-input {
  border: 1px solid #ceced4;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-top: 20px;
  margin-right: 47px;
}

.cart-screen-section .form-check-input:checked {
  background-color: #dbbb51;
}

.total-item-section {
  background: #f6f6fa;
  padding: 35px 50px;
  border-radius: 24px;
  margin-bottom: 60px;
}

.total-item-section h4 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  margin: 0;
  margin-bottom: 18px;
}

.total-item-section .subhr hr {
  background: #ceced4;
  height: 1px;
  margin: 0;
  margin-bottom: 30px;
}

.total-item-section .items {
  /* Sub Total: */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.0038em;
  color: #000000;
}

.total-item-section .total {
  /* $140.40 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  letter-spacing: 0.0038em;
  color: #000000;
}

.total-item-section .mt_15 {
  margin-top: 15px;
}

.total-item-section .est-time {
  /* (Est time. 14-16 Nov) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.0038em;
  color: #707581;
}

.total-item-section .totalhr hr {
  background: #000000;
  height: 1px;
  margin: 0;
  margin-top: 18px;
  margin-bottom: 15px;
}

.total-item-section .proceed-btn {
  background: #000000;
  color: #dbbb51;
  border: 1px solid #000000;
  height: 48px;
  margin-top: 40px;
  width: 100%;
  border-radius: 100px;
}

.total-item-section .proceed-btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  background: #000000 !important;
  color: #dbbb51 !important;
  border: 1px solid #000000;
}

.total-item-section .continue-btn {
  background: transparent;
  color: #000000;
  border: 1px solid #000000;
  height: 48px;
  margin-top: 40px;
  margin-top: 15px;
  width: 100%;
  border-radius: 100px;
}

.total-item-section .continue-btn:focus, .total-item-section .continue-btn:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  background: #000000 !important;
  color: #dbbb51 !important;
  border: 1px solid #000000;
}

.cartitemscard {
  margin-bottom: 20px;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.cartitemscard .cartimg-box {
  width: 75px;
  height: 75px;
  border-radius: 16px;
  -o-object-fit: contain;
     object-fit: contain;
}

.cartitemscard .cart-item-title {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 5px;
}

.cartitemscard .ps_20 {
  padding-left: 20px;
}

.cartitemscard .main-price {
  /* $120.20 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.cartitemscard .sub-price {
  /* $150.45 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #707581;
  padding-left: 10px;
}

.cartitemscard .sub-price strike {
  color: #707581;
}

.quantity-btn {
  width: 103px;
  height: 48px;
  border: 1px solid #000000;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 15px;
  margin-top: 10px;
}

.count-text {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: black;
}

.checkout {
  margin-bottom: 50px;
}

.checkout .form-floating select:not(:placeholder-shown) ~ label {
  background-color: white;
}

.checkout .add-new-card {
  /* Add New */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #707581;
  padding-left: 11px;
  padding-top: 5px;
  cursor: pointer;
}

.checkout .checkout-main-heading {
  /* Checkout */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 16px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 20px;
}

.checkout .checkout-subheading {
  /* Shipping Details */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 20px;
}

.checkout .checkout-checkbox .form-check-input {
  border: 1px solid #ceced4;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  margin-right: 13px;
}

.checkout .checkout-checkbox .form-check-input:checked {
  background-color: #dbbb51;
}

.checkout .payment-checkbox .form-check-input {
  border: 1px solid #ceced4;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-right: 13px;
  margin-top: 10px;
}

.checkout .payment-checkbox .form-check-input:checked {
  background-color: #dbbb51;
}

.total-items-container {
  background: #f6f6fa;
  border-radius: 24px;
  padding: 35px 50px;
}

.total-items-container .items-heading {
  /* Items (2) Total */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
}

.total-items-container .subhr hr {
  height: 1px;
  background: #ceced4;
  margin: 0;
  margin-top: 18px;
  margin-bottom: 36px;
}

.total-items-container .total-item-checkout-section h4 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  margin: 0;
  margin-bottom: 18px;
}

.total-items-container .total-item-checkout-section .subhr hr {
  background: #ceced4;
  height: 1px;
  margin: 0;
  margin-bottom: 30px;
}

.total-items-container .total-item-checkout-section .items {
  /* Sub Total: */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.0038em;
  color: #000000;
}

.total-items-container .total-item-checkout-section .total {
  /* $140.40 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  letter-spacing: 0.0038em;
  color: #000000;
}

.total-items-container .total-item-checkout-section .mt_15 {
  margin-top: 15px;
}

.total-items-container .total-item-checkout-section .est-time {
  /* (Est time. 14-16 Nov) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.0038em;
  color: #707581;
}

.total-items-container .total-item-checkout-section .totalhr hr {
  background: #000000;
  height: 1px;
  margin: 0;
  margin-top: 18px;
  margin-bottom: 15px;
}

.total-items-container .total-item-checkout-section .proceed-btn {
  background: #000000;
  color: #dbbb51;
  border: 1px solid #000000;
  height: 48px;
  margin-top: 40px;
  width: 100%;
  border-radius: 100px;
  font-weight: 700;
}

.total-items-container .total-item-checkout-section .proceed-btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  background: #000000 !important;
  color: #dbbb51 !important;
  border: 1px solid #000000;
}

.total-items-container .total-item-checkout-section .continue-btn {
  background: transparent;
  color: #000000;
  font-weight: 700;
  border: 1px solid #000000;
  height: 48px;
  margin-top: 40px;
  margin-top: 15px;
  width: 100%;
  border-radius: 100px;
}

.total-items-container .total-item-checkout-section .continue-btn:focus, .total-items-container .total-item-checkout-section .continue-btn:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  background: #000000 !important;
  color: #dbbb51 !important;
  border: 1px solid #000000;
}

.checkout-component .img-container {
  width: 75px;
  height: 75px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 16px;
  margin-right: 15px;
}

.checkout-component .product-checkout-title {
  /* DiW_ Shop 24/7 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1e1d1c;
}

.checkout-component .product-checkout-price {
  /* USD $48 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.checkout-component .checkout-price-and-quantity {
  /* $150.45 QTY: 1 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #707581;
}

.mainhr hr {
  height: 1px;
  background: #ceced4;
  margin: 0;
  margin: 25px 0;
}

.payment-card {
  margin-bottom: 25px;
}

.payment-card .payment-card-img {
  background: white;
  height: 46px;
  width: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #eae9e9;
  margin-right: 10px;
}

.payment-card .payment-card-title {
  /* Stripe (connected) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  letter-spacing: -0.24px;
  color: #000000;
}

.payment-card .payment-card-title span {
  color: #707581;
}

.payment-card .payment-code {
  /* •••• 1234 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #7c7c7e;
}

.review .shipping-heading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.review .data-ques {
  /* PN0001265 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  width: 60px;
}

.review .data-ans {
  /* PN0001265 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-left: 70px;
}

.review .mt_15 {
  margin-top: 15px;
}

.review .payment-heading {
  /* Payment Method */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  margin-top: 50px;
  margin-bottom: 20px;
}

.order-summary-page {
  background: #fbfbfd;
  border-radius: 10px;
  padding: 25px;
  height: 693px;
}

.order-summary-page .order-no {
  /* Dashboard */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.order-summary-page .order-no span {
  color: #dbbb51;
}

.checkout-signup .form-check-input {
  border: 1px solid #ceced4;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  margin-right: 13px;
}

.checkout-signup .form-check-input:checked {
  background-color: #dbbb51;
}

.checkout-signup label.form-check-label {
  /* I would like to receive additional news about offers. */
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3A3B3B;
}

.wishlist {
  margin-top: 30px;
}

.wishlist .wishlist-heading {
  /* Items in your wishlist (3) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.wishlist .cursor-link {
  cursor: pointer;
}

.wishlist .delete-txt {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f76356;
  padding-left: 10px;
}

.wishlist .unit-price {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #000000;
  margin-top: 22px;
}

.wishlist .grid-col {
  background: #f6f6fa;
  padding: 15px;
  /* Product name */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 16px;
}

.wishlist .product-name {
  /* Wood Ornament */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-left: 25px;
}

.wishlist .no-products-in-wishlist {
  /* No products added to the wishlist */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #77778D;
  margin-top: 40px;
  text-align: center;
}

.wishlist .wishlist-grid {
  height: 330px;
  margin-bottom: 50px;
  overflow: scroll;
}

.wishlist .wishlist-grid::-webkit-scrollbar {
  display: none;
}

.wishlist .wishlist-grid .form-check-input {
  border: 1px solid #ceced4;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-top: 20px;
  margin-right: 47px;
}

.wishlist .wishlist-grid .form-check-input:checked {
  background-color: #dbbb51;
}

.wishlist .wishlist-grid label {
  width: auto;
}

.select-role-form .select-role {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.select-role-form .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse;
          flex-flow: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}

.select-role-form .wrapper:has(input:checked) .icon-container {
  background: #dbbb51;
}

.select-role-form .wrapper:has(input:checked) label {
  border: 1px solid #000000;
}

.select-role-form .wrapper label {
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  padding: 2rem 1.5rem;
  border: 1px solid #ceced4;
  font-family: "Ageo", sans-serif;
  text-align: left;
  height: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #f6f6fa;
  border-radius: 20px;
  height: 90px;
}

.select-role-form .wrapper label span {
  font-weight: 700;
}

.select-role-form .wrapper label .icon-container {
  background: white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 15px;
}

.select-role-form .wrapper label:hover {
  border: 1px solid #dbbb51;
}

.select-role-form .wrapper input[type="radio"]:checked + label {
  background-color: black !important;
}

.select-role-form .wrapper .form-check-input {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 25px;
  height: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #f6f6fa;
}

.select-role-form .wrapper .form-check-input:checked {
  background-image: url("/src/assets/images/SelectRole/tickcircle.png");
  background-repeat: no-repeat;
  border: none;
}

.select-role-form .wrapper .form-check-input:checked ~ label {
  background-color: red;
  border: 5px solid #dbbb51;
}

.main-setting-menu {
  margin-top: 30px;
  background: #f6f6fa;
  border-radius: 16px;
  padding: 12px;
}

.main-setting-menu h2 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}

.main-setting-menu .setting-tab a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 16.8px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 8px;
  height: 40px;
  padding-left: 8px;
}

.main-setting-menu .setting-tab a .svg-div {
  width: 24px;
  height: 24px;
}

.main-setting-menu .setting-tab a .side-menu-link h4 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 0;
  padding-left: 8px;
}

.main-setting-menu .setting-tab a.active {
  border: 1.5px solid #dbbb51;
}

.main-setting-menu .setting-tab a.active rect {
  fill: #dbbb51;
}

.main-setting-menu .setting-tab a.active path {
  fill: #000000;
}

.main-setting-menu .logout-profile {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 16.8px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 8px;
  height: 40px;
  padding-left: 8px;
}

.main-setting-menu .logout-profile .svg-div {
  width: 24px;
  height: 24px;
}

.main-setting-menu .logout-profile .side-menu-link h4 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 0;
  padding-left: 8px;
}

.main-setting-menu .logout-profile.active {
  border: 1.5px solid #dbbb51;
}

.main-setting-menu .logout-profile.active rect {
  fill: #dbbb51;
}

.main-setting-menu .logout-profile.active path {
  fill: #000000;
}

/* Main Buyer Profile CSS */
.main-buyer-profile {
  margin-bottom: 70px;
}

/* Main Buyer Account Details CSS */
.main-buyer-account-details {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  min-height: 480px;
  padding-bottom: 20px;
}

.main-buyer-account-details .top-heading-edit-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 30px;
}

.main-buyer-account-details .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-buyer-account-details .top-heading-edit-div .edit-div a {
  font-family: "Ageo", sans-serif;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.main-buyer-account-details .buyer-account-content {
  margin-top: 32px;
}

.main-buyer-account-details .buyer-account-content .buyer-avatar {
  width: 108px;
  height: 108px;
}

.main-buyer-account-details .buyer-account-content .buyer-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.main-buyer-account-details .buyer-account-content .buyer-name {
  margin-top: 16px;
}

.main-buyer-account-details .buyer-account-content .buyer-name h2 {
  font-family: "Ageo", sans-serif;
  color: #000000;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.main-buyer-account-details .buyer-account-content .buyer-info {
  margin-top: 18px;
  /* padding-bottom: 35px; */
}

.main-buyer-account-details .buyer-account-content .buyer-info ul {
  padding: 0;
}

.main-buyer-account-details .buyer-account-content .buyer-info ul li {
  list-style: none;
  margin-bottom: 10px;
}

.main-buyer-account-details .buyer-account-content .buyer-info ul li span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
}

.main-buyer-account-details .buyer-account-content .buyer-info ul li span:first-child {
  font-family: "Ageo", sans-serif;
  margin-right: 20px;
  max-width: 130px;
  width: 100%;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

/* Main Buyer Address CSS */
.main-buyer-address {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  height: 471px;
}

.main-buyer-address .top-heading-edit-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 30px;
}

.main-buyer-address .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-buyer-address .buyer-account-content {
  margin-top: 15px;
}

.main-buyer-address .buyer-account-content .buyer-avatar p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #77778d;
}

.main-buyer-address .buyer-account-content .shipping-addnew-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-buyer-address .buyer-account-content .shipping-addnew-heading .shipping-heading {
  margin-top: 16px;
}

.main-buyer-address .buyer-account-content .shipping-addnew-heading .shipping-heading h2 {
  font-family: "Ageo", sans-serif;
  color: #000000;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.main-buyer-address .buyer-account-content .shipping-addnew-heading .add-new-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-buyer-address .buyer-account-content .shipping-addnew-heading .add-new-div .svg-div {
  width: 13px;
  height: 14px;
}

.main-buyer-address .buyer-account-content .shipping-addnew-heading .add-new-div .svg-div svg {
  display: block;
}

.main-buyer-address .buyer-account-content .shipping-addnew-heading .add-new-div .add-new-heading a {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding-left: 11px;
}

.main-buyer-address .buyer-account-content .buyer-cards {
  height: 278px;
  overflow: auto;
}

.main-buyer-address .buyer-account-content .buyer-cards::-webkit-scrollbar {
  display: none;
}

.main-buyer-address .buyer-account-content .buyer-cards .buyer-info {
  margin-top: 12px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main-buyer-address .buyer-account-content .buyer-cards .buyer-info .address-info ul {
  padding: 0;
  margin-bottom: 0;
}

.main-buyer-address .buyer-account-content .buyer-cards .buyer-info .address-info ul li {
  list-style: none;
  width: 400px;
}

.main-buyer-address .buyer-account-content .buyer-cards .buyer-info .address-info ul li span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
}

.main-buyer-address .buyer-account-content .buyer-cards .buyer-info .address-info ul li span:first-child {
  font-family: "Ageo", sans-serif;
  margin-right: 20px;
  max-width: 130px;
  width: 100%;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.main-buyer-address .buyer-account-content .buyer-cards .buyer-info .address-info ul li:not(:last-child) {
  margin-bottom: 9px;
}

.main-buyer-address .buyer-account-content .buyer-cards .buyer-info .edit-button-div a {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

/* Main Buyer Privacy */
.main-privacy-section {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  height: 435px;
  overflow: auto;
}

.main-privacy-section::-webkit-scrollbar {
  display: none;
}

.main-privacy-section .top-heading-edit-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 30px;
}

.main-privacy-section .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-privacy-section .privacy-content-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.main-privacy-section .privacy-content-div p:first-child {
  margin-top: 15px;
}

.main-privacy-section .privacy-content-div p:last-child {
  margin-top: 15px;
}

/* Main Buyer Terms */
.main-terms-section {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  height: 435px;
  overflow: auto;
}

.main-terms-section::-webkit-scrollbar {
  display: none;
}

.main-terms-section .top-heading-edit-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 30px;
}

.main-terms-section .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-terms-section .terms-content-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.main-terms-section .terms-content-div p:first-child {
  margin-top: 15px;
}

.main-terms-section .terms-content-div p:last-child {
  margin-top: 15px;
}

/* Main Buyer Contact Us */
.main-buyer-contact {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
}

.main-buyer-contact .top-heading-edit-div {
  padding-top: 30px;
}

.main-buyer-contact .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-buyer-contact .top-heading-edit-div .heading-div p {
  margin-bottom: 0;
  margin-top: 8px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #020203;
}

.main-buyer-contact .buyer-contact-fields {
  margin-top: 30px;
}

.main-buyer-contact .buyer-contact-fields form .form-floating label {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding: 13px 16px;
}

.main-buyer-contact .buyer-contact-fields form .form-floating label:focus {
  position: absolute;
  top: -10px !important;
}

.main-buyer-contact .buyer-contact-fields form .form-floating input {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 25px;
  padding: 13px 16px !important;
}

.main-buyer-contact .buyer-contact-fields form .form-floating input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-contact .buyer-contact-fields form .form-floating input:focus label {
  color: blue !important;
}

.main-buyer-contact .buyer-contact-fields form .form-floating input:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-contact .buyer-contact-fields form .form-floating input:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-contact .buyer-contact-fields form .form-floating textarea {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  height: 100px;
  resize: none;
}

.main-buyer-contact .buyer-contact-fields form .form-floating textarea::-webkit-scrollbar {
  display: none;
}

.main-buyer-contact .buyer-contact-fields form .form-floating textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-contact .buyer-contact-fields form .form-floating textarea:focus label {
  color: blue !important;
}

.main-buyer-contact .buyer-contact-fields form .form-floating textarea:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-contact .buyer-contact-fields form .form-floating textarea:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-contact .buyer-contact-fields form .form-floating select {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  resize: none;
}

.main-buyer-contact .buyer-contact-fields form .form-floating select::-webkit-scrollbar {
  display: none;
}

.main-buyer-contact .buyer-contact-fields form .form-floating select:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-contact .buyer-contact-fields form .form-floating select:focus label {
  color: blue !important;
}

.main-buyer-contact .buyer-contact-fields form .form-floating select:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-contact .buyer-contact-fields form .form-floating select:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-contact .buyer-contact-fields form .form-group {
  margin-bottom: 20px;
}

.main-buyer-contact .buyer-contact-fields form .form-label {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
  background-color: transparent;
}

.main-buyer-contact .buyer-contact-fields form .form-control {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
  background: transparent;
  border: 1px solid #ceced4;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
}

.main-buyer-contact .buyer-contact-fields form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-contact .buyer-contact-fields form .form-control::-webkit-input-placeholder {
  display: none;
}

.main-buyer-contact .buyer-contact-fields form .form-control:-ms-input-placeholder {
  display: none;
}

.main-buyer-contact .buyer-contact-fields form .form-control::-ms-input-placeholder {
  display: none;
}

.main-buyer-contact .buyer-contact-fields form .form-control::placeholder {
  display: none;
}

.main-buyer-contact .buyer-contact-fields form .form-select {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background-color: transparent;
  border: 1px solid #ebebf9;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  background-image: url("../images/BuyerProfile/drop-down-arrow.png");
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  display: block;
  width: 100%;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
}

.main-buyer-contact .buyer-contact-fields form .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-contact .buyer-contact-fields form .button-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.main-buyer-contact .buyer-contact-fields form .button-div button {
  margin-top: 10px;
  margin-bottom: 40px;
  background: #000000;
  border-radius: 20px;
  height: 48px;
  max-width: 200px;
  width: 100%;
  color: #dbbb51;
  text-transform: capitalize;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border: none;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.main-buyer-contact .buyer-contact-fields form .button-div button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-contact .buyer-contact-fields form .button-div button:hover {
  background-color: #dbbb51;
  color: #ffffff;
}

/* Main Buyer Change Password */
.main-buyer-changepassword {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
}

.main-buyer-changepassword .top-heading-edit-div {
  padding-top: 30px;
}

.main-buyer-changepassword .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-buyer-changepassword .top-heading-edit-div .heading-div p {
  margin-bottom: 0;
  margin-top: 8px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #020203;
}

.main-buyer-changepassword .buyer-contact-fields {
  margin-top: 25px;
}

.main-buyer-changepassword .buyer-contact-fields form .form-floating label {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding: 13px 16px;
}

.main-buyer-changepassword .buyer-contact-fields form .form-floating label:focus {
  position: absolute;
  top: -10px !important;
}

.main-buyer-changepassword .buyer-contact-fields form .form-floating input {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 25px;
  padding: 13px 16px !important;
  position: relative;
}

.main-buyer-changepassword .buyer-contact-fields form .form-floating input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-changepassword .buyer-contact-fields form .form-floating input:focus label {
  color: blue !important;
}

.main-buyer-changepassword .buyer-contact-fields form .form-floating input:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-changepassword .buyer-contact-fields form .form-floating input:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-changepassword .buyer-contact-fields form .form-group {
  margin-bottom: 20px;
}

.main-buyer-changepassword .buyer-contact-fields form .form-label {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
  background-color: transparent;
}

.main-buyer-changepassword .buyer-contact-fields form .form-control {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
  background: transparent;
  border: 1px solid #ceced4;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
}

.main-buyer-changepassword .buyer-contact-fields form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-changepassword .buyer-contact-fields form .form-control::-webkit-input-placeholder {
  display: none;
}

.main-buyer-changepassword .buyer-contact-fields form .form-control:-ms-input-placeholder {
  display: none;
}

.main-buyer-changepassword .buyer-contact-fields form .form-control::-ms-input-placeholder {
  display: none;
}

.main-buyer-changepassword .buyer-contact-fields form .form-control::placeholder {
  display: none;
}

.main-buyer-changepassword .buyer-contact-fields form .form-select {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #ffffff;
  border: 1px solid #ebebf9;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  background-image: url("../images/BuyerProfile/drop-down-arrow.png");
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  display: block;
  width: 100%;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
}

.main-buyer-changepassword .buyer-contact-fields form .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-changepassword .buyer-contact-fields form .button-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.main-buyer-changepassword .buyer-contact-fields form .button-div button {
  margin-top: 10px;
  margin-bottom: 40px;
  background: #000000;
  border-radius: 20px;
  height: 48px;
  max-width: 200px;
  width: 100%;
  color: #dbbb51;
  text-transform: capitalize;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border: none;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.main-buyer-changepassword .buyer-contact-fields form .button-div button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-changepassword .buyer-contact-fields form .button-div button:hover {
  background-color: #dbbb51;
  color: #ffffff;
}

/* Main Buyer Account Details Edit CSS */
.main-buyer-account-details-edit {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
}

.main-buyer-account-details-edit .top-heading-edit-div {
  padding-top: 30px;
}

.main-buyer-account-details-edit .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-buyer-account-details-edit .buyer-account-content {
  margin-top: 32px;
  position: relative;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-avatar {
  width: 108px;
  height: 108px;
  width: 108px;
  height: 108px;
  border-radius: 50%;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.main-buyer-account-details-edit .buyer-account-content .camera-div {
  position: absolute;
  top: 78px;
  /* bottom: 0; */
  left: 70px;
  /* bottom: 55px; */
  width: 32px;
  background-color: #fdfdff;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #f6f6fa;
  cursor: pointer;
}

.main-buyer-account-details-edit .buyer-account-content .camera-div label {
  cursor: pointer;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-name {
  margin-top: 16px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-name h2 {
  font-family: "Ageo", sans-serif;
  color: #000000;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-info {
  margin-top: 20px;
  padding-bottom: 35px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-info ul {
  padding: 0;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-info ul li {
  list-style: none;
  margin-bottom: 15px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-info ul li span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-info ul li span:first-child {
  font-family: "Ageo", sans-serif;
  margin-right: 20px;
  max-width: 130px;
  width: 100%;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields {
  margin-top: 20px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating label {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding: 13px 16px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating label:focus {
  position: absolute;
  top: -10px !important;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating input {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 30px;
  padding: 13px 16px !important;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating input:focus label {
  color: blue !important;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating input:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating input:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating select {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 30px;
  padding: 13px 16px !important;
  resize: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating select::-webkit-scrollbar {
  display: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating select:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating select:focus label {
  color: blue !important;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating select:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-floating select:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-group {
  margin-bottom: 20px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-label {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
  background-color: transparent;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-control {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
  background: transparent;
  border: 1px solid #ceced4;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-control::-webkit-input-placeholder {
  display: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-control:-ms-input-placeholder {
  display: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-control::-ms-input-placeholder {
  display: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-control::placeholder {
  display: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-select {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  border: 1px solid #ebebf9;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  background-image: url("../images/BuyerProfile/drop-down-arrow.png");
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  display: block;
  width: 100%;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .button-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .button-div button {
  margin-top: 10px;
  margin-bottom: 40px;
  background: #000000;
  border-radius: 20px;
  height: 48px;
  max-width: 200px;
  width: 100%;
  color: #dbbb51;
  text-transform: capitalize;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border: none;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .button-div button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-account-details-edit .buyer-account-content .buyer-contact-fields form .button-div button:hover {
  background-color: #dbbb51;
  color: #ffffff;
}

/* Main Buyer Address Edit CSS */
.main-buyer-address-edit {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
}

.main-buyer-address-edit .top-heading-edit-div {
  padding-top: 30px;
}

.main-buyer-address-edit .top-heading-edit-div .heading-div h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: "Ageo", sans-serif;
  color: #000000;
}

.main-buyer-address-edit .buyer-account-content {
  margin-top: 32px;
  position: relative;
}

.main-buyer-address-edit .buyer-account-content .buyer-avatar {
  width: 108px;
  height: 108px;
  width: 108px;
  height: 108px;
  border-radius: 50%;
}

.main-buyer-address-edit .buyer-account-content .buyer-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.main-buyer-address-edit .buyer-account-content .camera-div {
  position: absolute;
  top: 78px;
  left: 70px;
  width: 32px;
  background-color: #fdfdff;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #f6f6fa;
  cursor: pointer;
}

.main-buyer-address-edit .buyer-account-content .camera-div label {
  cursor: pointer;
}

.main-buyer-address-edit .buyer-account-content .buyer-name {
  margin-top: 16px;
}

.main-buyer-address-edit .buyer-account-content .buyer-name h2 {
  font-family: "Ageo", sans-serif;
  color: #000000;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.main-buyer-address-edit .buyer-account-content .buyer-info {
  margin-top: 20px;
  padding-bottom: 35px;
}

.main-buyer-address-edit .buyer-account-content .buyer-info ul {
  padding: 0;
}

.main-buyer-address-edit .buyer-account-content .buyer-info ul li {
  list-style: none;
  margin-bottom: 15px;
}

.main-buyer-address-edit .buyer-account-content .buyer-info ul li span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
}

.main-buyer-address-edit .buyer-account-content .buyer-info ul li span:first-child {
  font-family: "Ageo", sans-serif;
  margin-right: 20px;
  max-width: 130px;
  width: 100%;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields {
  margin-top: 20px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating label {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding: 13px 16px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating label:focus {
  position: absolute;
  top: -10px !important;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating input {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 30px;
  padding: 13px 16px !important;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating input:focus label {
  color: blue !important;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating input:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating input:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating select {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 30px;
  padding: 13px 16px !important;
  resize: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating select::-webkit-scrollbar {
  display: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating select:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating select:focus label {
  color: blue !important;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating select:not(:placeholder-shown) ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-floating select:focus ~ label {
  background: #f6f6fa;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-group {
  margin-bottom: 20px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-label {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
  background-color: transparent;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-control {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
  background: transparent;
  border: 1px solid #ceced4;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-control::-webkit-input-placeholder {
  display: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-control:-ms-input-placeholder {
  display: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-control::-ms-input-placeholder {
  display: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-control::placeholder {
  display: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-select {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  border: 1px solid #ebebf9;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  background-image: url("../images/BuyerProfile/drop-down-arrow.png");
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  display: block;
  width: 100%;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .button-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .button-div button {
  margin-top: 10px;
  margin-bottom: 40px;
  background: #000000;
  border-radius: 20px;
  height: 48px;
  max-width: 200px;
  width: 100%;
  color: #dbbb51;
  text-transform: capitalize;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border: none;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .button-div button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-buyer-address-edit .buyer-account-content .buyer-contact-fields form .button-div button:hover {
  background-color: #dbbb51;
  color: #ffffff;
}

/* Main Buyer My Orders CSS */
.main-my-orders {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-right: 20px;
}

.main-my-orders .tab-buttons-section {
  padding-top: 12px;
  margin-left: 12px;
}

.main-my-orders .tab-buttons-section li {
  line-height: 17px;
  height: 33px;
  width: 103px;
}

.main-my-orders .tab-buttons-section li:not(:first-child) {
  margin-left: 18px;
}

.main-my-orders .tab-buttons-section li .nav-link {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px;
  background: #ffffff;
  color: #000000;
  width: 100%;
}

.main-my-orders .tab-buttons-section li .nav-link.active {
  border: 1.5px solid #dbbb51;
}

.main-my-orders .tab-content {
  margin-top: 28px;
}

.main-my-orders .tab-content .tab-pane .main-order-count {
  margin-bottom: 22px;
  display: inline-block;
}

.main-my-orders .tab-content .tab-pane .main-order-count span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #979797;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper {
  border-top: 1px solid rgba(112, 117, 129, 0.1);
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .orderid-view-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 26px;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .orderid-view-details .order-date p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .orderid-view-details .order-date p span {
  color: #707581;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .orderid-view-details .order-date p:not(:first-child) {
  padding-top: 6px;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .orderid-view-details .view-details a {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #707581;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .order-details {
  margin-top: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .order-details .order-img-div {
  width: 66px;
  height: 66px;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .order-details .order-img-div img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .order-details .order-content {
  margin-left: 16px;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .order-details .order-content h5 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .order-details .order-content span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3a3b3b;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .order-details .order-content p {
  margin-bottom: 0;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .quantity-total-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 22px;
  padding-bottom: 21px;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .quantity-total-amount p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #979797;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .quantity-total-amount h5 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.main-my-orders .tab-content .tab-pane .order-card-wrapper .quantity-total-amount h5 span {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

/* Main Buyer Order Detail CSS */
.main-order-details {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-top: 20px;
}

.main-order-details .orderid-status-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-order-details .orderid-status-div .orderid-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 22px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-order-details .orderid-status-div .orderid-div h5 {
  margin-bottom: 0;
  margin-left: 15px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.main-order-details .orderid-status-div .orderid-div h5 span {
  color: #dbbb51;
}

.main-order-details .orderid-status-div .status-div {
  padding-right: 25px;
}

.main-order-details .orderid-status-div .status-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 13px;
  color: #09acfe;
  text-transform: capitalize;
}

.main-order-details .product-total-div {
  margin-top: 21px;
  margin-left: 22px;
  margin-right: 21px;
  padding-bottom: 21px;
  border-bottom: 1px solid rgba(112, 117, 129, 0.1);
}

.main-order-details .product-total-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

.main-order-details .product-detail-amount {
  margin-left: 22px;
  margin-right: 21px;
  border-bottom: 1px solid rgba(112, 117, 129, 0.1);
  padding-bottom: 21px;
}

.main-order-details .product-detail-amount .order-card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}

.main-order-details .product-detail-amount .order-card-wrapper .order-img-div {
  width: 40px;
  height: 40px;
}

.main-order-details .product-detail-amount .order-card-wrapper .order-img-div img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.main-order-details .product-detail-amount .order-card-wrapper .order-info {
  padding-left: 11px;
}

.main-order-details .product-detail-amount .order-card-wrapper .order-info p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.main-order-details .product-detail-amount .order-card-wrapper .order-info p span {
  font-weight: 600;
}

.main-order-details .product-detail-amount .amount-div {
  margin-top: 20px;
}

.main-order-details .product-detail-amount .amount-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.main-order-details .product-sub-total {
  margin-left: 22px;
  margin-right: 21px;
  border-bottom: 1px solid rgba(112, 117, 129, 0.1);
  padding-bottom: 21px;
}

.main-order-details .product-sub-total .sub-total-heading {
  margin-top: 20px;
}

.main-order-details .product-sub-total .sub-total-heading h5 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.main-order-details .product-sub-total .amount-div {
  margin-top: 20px;
}

.main-order-details .product-sub-total .amount-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.main-order-details .product-vat {
  margin-left: 22px;
  margin-right: 21px;
  border-bottom: 1px solid rgba(112, 117, 129, 0.1);
  padding-bottom: 21px;
}

.main-order-details .product-vat .vat-heading {
  margin-top: 20px;
}

.main-order-details .product-vat .vat-heading h5 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.main-order-details .product-vat .amount-div {
  margin-top: 20px;
}

.main-order-details .product-vat .amount-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.main-order-details .product-shipping-rate {
  margin-left: 22px;
  margin-right: 21px;
  border-bottom: 1px solid rgba(112, 117, 129, 0.1);
  padding-bottom: 21px;
}

.main-order-details .product-shipping-rate .shipping-rate-heading {
  margin-top: 20px;
}

.main-order-details .product-shipping-rate .shipping-rate-heading h5 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.main-order-details .product-shipping-rate .amount-div {
  margin-top: 20px;
}

.main-order-details .product-shipping-rate .amount-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.main-order-details .product-shipping-rate .amount-div h5 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0038em;
  color: #707581;
}

.main-order-details .product-total-amount {
  margin-left: 22px;
  margin-right: 21px;
  padding-bottom: 21px;
}

.main-order-details .product-total-amount .total-amount-heading {
  margin-top: 20px;
}

.main-order-details .product-total-amount .total-amount-heading h5 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.main-order-details .product-total-amount .amount-div {
  margin-top: 20px;
}

.main-order-details .product-total-amount .amount-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.main-order-details .main-cancel-reason {
  margin-left: 22px;
  margin-right: 21px;
  padding-bottom: 21px;
}

.main-order-details .main-cancel-reason .cancel-reason-div {
  margin-top: 20px;
}

.main-order-details .main-cancel-reason .cancel-reason-div h5 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0038em;
  color: #000000;
}

.main-order-details .main-cancel-reason .cancel-reason-div p {
  margin-bottom: 0;
  padding-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  font-family: "Ageo", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 0.0038em;
  color: #707581;
}

.main-order-details .main-buttons-div {
  margin-left: 22px;
  margin-right: 21px;
  padding-bottom: 21px;
}

.main-order-details .main-buttons-div .buttons-div {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.main-order-details .main-buttons-div .buttons-div .left-div {
  padding-right: 12px;
}

.main-order-details .main-buttons-div .buttons-div .left-div a {
  font-family: "Ageo", sans-serif;
  border: 1px solid #000000;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #000000;
  height: 48px;
  width: 200px;
  display: inline-block;
  text-align: center;
}

.main-order-details .main-buttons-div .buttons-div .left-div a:hover {
  border: 1px solid #dbbb51;
  color: #dbbb51;
}

.main-order-details .main-buttons-div .buttons-div .left-div button {
  font-family: "Ageo", sans-serif;
  border: 1px solid #000000;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  background-color: transparent;
  color: #000000;
  height: 48px;
  width: 200px;
  text-align: center;
}

.main-order-details .main-buttons-div .buttons-div .left-div button:hover {
  border: 1px solid #dbbb51;
  color: #dbbb51;
}

.main-order-details .main-buttons-div .buttons-div .right-div a {
  font-family: "Ageo", sans-serif;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #dbbb51;
  height: 48px;
  width: 200px;
  display: inline-block;
  background-color: #000000;
  text-align: center;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.main-order-details .main-buttons-div .buttons-div .right-div a:hover {
  background-color: #dbbb51;
  color: #ffffff;
  border: none;
}

.main-order-details .main-buttons-div .buttons-div .right-div button {
  font-family: "Ageo", sans-serif;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #dbbb51;
  height: 48px;
  width: 200px;
  background-color: #000000;
  text-align: center;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  border: none;
}

.main-order-details .main-buttons-div .buttons-div .right-div button:hover {
  background-color: #dbbb51;
  color: #ffffff;
  border: none;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Main Transaction History */
.main-transaction-history {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-top: 20px;
  height: 604px;
}

.main-transaction-history .heading-search-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-transaction-history .heading-search-div .heading-div {
  padding-left: 30px;
}

.main-transaction-history .heading-search-div .heading-div h5 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #020203;
}

.main-transaction-history .heading-search-div .svg-div button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 28px;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  width: 50px;
  height: 50px;
  border-radius: 14px;
  background-color: #ffffff;
  text-align: center;
  line-height: 50px;
}

.main-transaction-history .heading-search-div .svg-div .searchbar-div .input-group {
  height: 50px;
}

.main-transaction-history .heading-search-div .svg-div .searchbar-div .input-group .input-group-text {
  background-color: white;
  border: 0;
}

.main-transaction-history .heading-search-div .svg-div .searchbar-div .input-group
> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
.valid-feedback
):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-transaction-history .heading-search-div .svg-div .searchbar-div .cross-icon {
  cursor: pointer;
}

.main-transaction-history .heading-search-div .svg-div .searchbar-div .cross-icon .input-group-text {
  background-color: white;
  border: 0;
  margin-left: -5px;
}

.main-transaction-history .heading-search-div .svg-div .searchbar-div input {
  border: none;
  margin-left: -2px;
}

.main-transaction-history .heading-search-div .svg-div .searchbar-div input:focus {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-transaction-history .main-order-view {
  padding-left: 31px;
  margin-top: 8px;
  padding-right: 28px;
  height: 480px;
  overflow: auto;
}

.main-transaction-history .main-order-view::-webkit-scrollbar {
  display: none;
}

.main-transaction-history .main-order-view .top-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 644px;
  padding-bottom: 21px;
}

.main-transaction-history .main-order-view .top-header h5 {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  margin-bottom: 0;
}

.main-transaction-history .main-order-view table thead {
  border-bottom: transparent;
}

.main-transaction-history .main-order-view table thead th {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  margin-bottom: 0;
  padding-left: 0;
}

.main-transaction-history .main-order-view table tbody tr {
  border-bottom: transparent;
}

.main-transaction-history .main-order-view table tbody tr td {
  padding-left: 0;
  vertical-align: middle;
  text-align: center;
  width: 20%;
}

.main-transaction-history .main-order-view table tbody tr td:last-child {
  padding-right: 0;
  text-align: end;
}

.main-transaction-history .main-order-view .img-id-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-transaction-history .main-order-view .img-id-div .img-div {
  width: 40px;
  height: 40px;
  margin-bottom: 0;
}

.main-transaction-history .main-order-view .img-id-div .img-div img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.main-transaction-history .main-order-view .img-id-div .order-id {
  padding-left: 11px;
}

.main-transaction-history .main-order-view .img-id-div .order-id p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.main-transaction-history .main-order-view .date-time-div span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.main-transaction-history .main-order-view .status-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  color: #069952;
}

.main-transaction-history .main-order-view .total-amount-div p {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.main-transaction-history .main-order-view .view-details-div a {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #707581;
}

/* Main Transaction History */
.main-transaction-detail {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-top: 20px;
  min-height: 475px;
}

.main-transaction-detail .orderid-download-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-transaction-detail .orderid-download-div .orderid-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 22px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-transaction-detail .orderid-download-div .orderid-div h5 {
  margin-bottom: 0;
  margin-left: 15px;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.main-transaction-detail .orderid-download-div .orderid-div h5 span {
  color: #dbbb51;
}

.main-transaction-detail .orderid-download-div .download-div {
  padding-right: 25px;
}

.main-transaction-detail .orderid-download-div .download-div button {
  border: none;
  background: transparent;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 39px;
  text-transform: capitalize;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 20px;
  width: 141px;
  height: 39px;
  display: block;
  text-align: center;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}

.main-transaction-detail .orderid-download-div .download-div button:hover {
  border: 1px solid #dbbb51;
  color: #dbbb51;
}

.main-transaction-detail .orderid-download-div .download-div button:hover svg path {
  fill: #dbbb51;
  stroke: #dbbb51;
}

.main-transaction-detail .border-bottomm {
  border-bottom: 1px solid rgba(112, 117, 129, 0.1);
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 20px;
}

.main-transaction-detail .order-detail-div {
  margin-top: 20px;
}

.main-transaction-detail .order-detail-div ul {
  margin-bottom: 0;
}

.main-transaction-detail .order-detail-div ul li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
}

.main-transaction-detail .order-detail-div ul li span {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.main-transaction-detail .order-detail-div ul li span:first-child {
  width: 170px;
  display: block;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.main-transaction-detail .order-detail-div ul li .master-div span {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #7c7c7e;
}

.main-transaction-detail .order-detail-div ul li .master-div span:first-child {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 2px;
}

.transaction-red-color {
  color: #c6000f !important;
  font-family: "Ageo", sans-serif;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-transform: capitalize;
}

.transaction-green-color {
  color: #069952 !important;
  font-family: "Ageo", sans-serif;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-transform: capitalize;
}

.img-div {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 24px;
}

.img-div img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.img-div .file-upload {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-transaction-history .heading-search-div {
    display: block;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .main-transaction-history .heading-search-div .svg-div button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 28px;
    margin-top: 10px;
    width: 50px;
    height: 50px;
    border-radius: 14px;
    background-color: #ffffff;
    text-align: center;
    line-height: 50px;
  }
  .searchbar-div {
    margin-left: 28px;
    margin-top: 10px;
  }
  .main-transaction-history .heading-search-div .svg-div .searchbar-div input {
    width: 140px;
  }
}

.cancel-order-modal .modal-dialog .modal-content {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
          box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
  max-width: 431px;
  height: 306px;
  width: 100%;
  padding-left: 21px;
  padding-right: 21px;
}

.cancel-order-modal .modal-dialog .modal-content .modal-header {
  border: none;
}

.cancel-order-modal .modal-dialog .modal-content h4 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.cancel-order-modal .modal-dialog .modal-content .close-div button {
  background-color: transparent;
  background-color: #f9fafa;
  height: 43px;
  text-align: center;
  border-radius: 13px;
  border: none;
}

.cancel-order-modal .modal-dialog .modal-content .modal-body textarea {
  height: 128px;
  max-width: 387px;
  resize: none;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.cancel-order-modal .modal-dialog .modal-content .modal-body textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-color: #dbbb51;
}

.cancel-order-modal .modal-dialog .modal-content .modal-body textarea::-webkit-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.cancel-order-modal .modal-dialog .modal-content .modal-body textarea:-ms-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.cancel-order-modal .modal-dialog .modal-content .modal-body textarea::-ms-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.cancel-order-modal .modal-dialog .modal-content .modal-body textarea::placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.cancel-order-modal .modal-dialog .modal-content .submit-div button {
  display: block;
  background: #000000;
  border-radius: 20px;
  margin-left: auto;
  margin-top: 20px;
  max-width: 160px;
  height: 48px;
  width: 100%;
  border: none;
  color: #dbbb51;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  -webkit-transition: .1s ease-in;
  transition: .1s ease-in;
}

.cancel-order-modal .modal-dialog .modal-content .submit-div button:hover {
  background: #dbbb51;
  color: #ffffff;
}

.review-order-modal .modal-dialog .modal-content {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
          box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
  max-width: 570px;
  height: 428px;
  width: 100%;
  margin: 0 auto;
}

.review-order-modal .modal-dialog .modal-content .modal-header {
  padding-bottom: 13px;
  border-color: #e3e3f1;
}

.review-order-modal .modal-dialog .modal-content h4 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  padding-left: 24px;
}

.review-order-modal .modal-dialog .modal-content .close-div button {
  background-color: transparent;
  background-color: #f9fafa;
  height: 43px;
  text-align: center;
  border-radius: 13px;
  border: none;
  margin-right: 22px;
}

.review-order-modal .modal-dialog .modal-content .modal-body {
  padding-top: 0px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .main-rating-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.review-order-modal .modal-dialog .modal-content .modal-body .main-rating-div .start-rating-section {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 24px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .main-rating-div .start-rating-section .stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.review-order-modal .modal-dialog .modal-content .modal-body .main-rating-div .start-rating-section .react-stars {
  overflow: hidden;
  position: relative;
  bottom: 10px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .main-rating-div .start-rating-section .react-stars span {
  padding-right: 12px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form {
  padding-left: 24px;
  padding-right: 24px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating label {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
  padding: 13px 16px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating label:focus {
  position: absolute;
  top: -10px !important;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating input {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 25px;
  padding: 13px 16px !important;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating input:focus label {
  color: blue !important;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating input:not(:placeholder-shown) ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating input:focus ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating textarea {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  height: 100px;
  resize: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating textarea::-webkit-scrollbar {
  display: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #dbbb51;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating textarea:focus label {
  color: blue !important;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating textarea:not(:placeholder-shown) ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-floating textarea:focus ~ label {
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: 0;
  opacity: 1;
  display: inline;
  width: auto;
  height: auto;
  left: 12px;
  padding: 0 8px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-group {
  margin-bottom: 20px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-label {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
  background-color: transparent;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-control {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b3b;
  background: transparent;
  border: 1px solid #ceced4;
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-control::-webkit-input-placeholder {
  display: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-control:-ms-input-placeholder {
  display: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-control::-ms-input-placeholder {
  display: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .form-control::placeholder {
  display: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .button-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .button-div button {
  margin-top: 10px;
  margin-bottom: 40px;
  background: #000000;
  border-radius: 100px;
  height: 48px;
  max-width: 160px;
  width: 100%;
  color: #dbbb51;
  text-transform: capitalize;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border: none;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .button-div button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.review-order-modal .modal-dialog .modal-content .modal-body .buyer-contact-fields form .button-div button:hover {
  background-color: #dbbb51;
  color: #ffffff;
}

.review-order-modal .modal-dialog .modal-content .modal-body .border-bottomm {
  border-bottom: 1px solid #e3e3f1;
  margin-bottom: 19px;
}

.review-order-modal .modal-dialog .modal-content .modal-body textarea {
  height: 120px;
  max-width: 521px;
  resize: none;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.review-order-modal .modal-dialog .modal-content .modal-body textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-color: #dbbb51;
}

@media (max-width: 1400px) {
  .nav-multidropdown .test .new-test .new-test-result {
    display: none;
    position: absolute;
    background: #fdfdff;
    width: 450px;
    height: 100%;
    left: 228px;
    top: 0;
    border-left: 1px solid #e3e3f1;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 50px;
  }
  .nav-multidropdown .test {
    top: 20px;
    display: none;
    position: absolute;
    background: #fdfdff;
    height: 628px;
    width: 230px;
    z-index: 999;
    padding-top: 15px;
  }
  /* QA fixes height */
  .auth-container {
    height: 600px;
  }
}

@media (max-width: 1200px) {
  .navbar-bg .header-bottombar .menu-options ul li {
    margin-right: 15px;
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .product-details-accordion {
    margin-top: 40px;
  }
  .product-card-bg {
    min-width: 100%;
    min-height: 350px;
    background: #f6f6fa;
    border-radius: 16px;
  }
}

@media (max-width: 1050px) {
  .cart-screen-section .form-check-input {
    border: 1px solid #ceced4;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    margin-top: 20px;
    margin-right: 20px;
  }
  .auth-container {
    background: unset;
  }
  .auth-main-section-margin {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .banner-bg {
    height: 100%;
    padding: 30px 0;
  }
  .add-to-cart-modal .modal-dialog {
    max-width: 100% !important;
  }
  .add-to-cart-modal .modal-dialog .modal-content {
    min-width: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .add-to-cart-modal .modal-dialog .modal-content {
    background: #ffffff;
    border-radius: 14px;
    height: 100% !important;
    min-width: 100%;
  }
  .tab-buttons-section .nav-pills {
    display: block;
  }
  .main-order-view .table {
    width: 500px;
    overflow: scroll;
  }
  .main-my-orders .tab-buttons-section li:not(:first-child) {
    margin-left: 0;
    margin-top: 10px;
  }
  .data-section {
    margin-left: 0 !important;
    margin-top: 20px;
  }
  .footer-title {
    margin-top: 20px;
    font-weight: 700 !important;
    font-size: 20px !important;
  }
  .buyer-product-details .tab-btn-container {
    margin-bottom: 20px;
  }
  .buyer-product-details .side-border {
    border-left: none;
    padding-left: 0;
    height: 100%;
  }
  .buyer-product-details .side-border .buyer-product-title {
    font-size: 30px;
    font-weight: 600;
    margin-top: 30px;
  }
  .buyer-product-details .side-border .made-in-section {
    width: 100%;
  }
  .buyer-product-details .add-to-cart-btn {
    margin-top: 30px;
  }
  .product-listing-banner .banner-container .banner-title {
    width: 100%;
  }
  .order-summary-page {
    background: #fbfbfd;
    border-radius: 10px;
    padding: 25px;
    height: 100%;
  }
  .total-items-container {
    margin-top: 20px;
  }
  .heart-container {
    margin-top: 20px;
  }
  .buyer-navbar-profile {
    margin-top: 20px;
  }
  .auth-container {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 576px) {
  .profile-modal .modal-dialog {
    margin-right: 0;
  }
  .modal-content {
    width: 100% !important;
    margin: 10px;
  }
}

@media (max-width: 450px) {
  .main-buyer-account-details .buyer-account-content .buyer-info ul li span {
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3a3b3b;
    display: block !important;
  }
  .main-buyer-account-details {
    padding-bottom: 20px;
  }
  .buyer-product-details .tab-btn-container {
    margin-bottom: 20px;
  }
  .buyer-product-details .side-border {
    border-left: none;
    padding-left: 0;
    height: 100%;
  }
  .buyer-product-details .side-border .size {
    margin-top: 10px;
  }
  .buyer-product-details .side-border .buyer-product-title {
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
  }
  .buyer-product-details .side-border .made-in-section {
    width: 100%;
  }
  .buyer-product-details .add-to-cart-btn {
    margin-top: 30px;
  }
  .product-listing-banner .banner-container {
    padding-left: 15px;
  }
  .category-btn-offcanvas .boxes-section .category-boxes {
    width: 100%;
  }
  .category-btn-offcanvas {
    padding: 10px;
  }
  .filter-btn-offcanvas .filter-conditions-section {
    padding-left: 0;
  }
  .filter-btn-offcanvas .filter-heading {
    margin-left: 0;
  }
}

@media (max-width: 420px) {
  .wishlist .wishlist-grid {
    overflow-x: scroll;
  }
  .wishlist .wishlist-grid .form-check-input {
    border: 1px solid #ceced4;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    margin-top: 20px;
    margin-right: 20px;
  }
  .wishlist .product-name {
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    padding-left: 15px;
  }
  .total-item-section {
    background: #f6f6fa;
    padding: 15px 20px;
    border-radius: 24px;
    margin-bottom: 60px;
  }
  .total-items-container {
    background: #f6f6fa;
    border-radius: 24px;
    padding: 15px 20px;
  }
}

div#offcanvasNavbar-expand-md {
  background: #f6f6fa;
}

div#offcanvasNavbar-expand-md .divider hr {
  /* Separator */
  height: 1px;
  background: #e3e3f1;
  border-radius: 8px;
  margin-top: 0;
}

div#offcanvasNavbar-expand-md .search-bar input.form-control {
  border-radius: 14px;
  border: none;
  border: 1px solid #e3e3f1;
  border-right: none;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8888a2;
  padding: 14px;
  max-width: 100%;
  height: 48px;
  background: white;
  border-radius: 14px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

div#offcanvasNavbar-expand-md .search-bar input.form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

div#offcanvasNavbar-expand-md .search-bar span#basic-addon2 {
  background: wheat;
  background: white;
  border: 1px solid #e3e3f1;
  border-left: none;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

div#offcanvasNavbar-expand-md .signin-btn {
  background: #dbbb51;
  width: 79px;
  height: 48px;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #020203;
  border: none;
  border-radius: 20px;
}

div#offcanvasNavbar-expand-md .signin-btn:focus, div#offcanvasNavbar-expand-md .signin-btn:hover {
  background: #1e1d1c;
  color: #dbbb51;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

div#offcanvasNavbar-expand-md .heart-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  width: 48px;
  height: 48px;
  background: #fdfdff;
  -webkit-box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
          box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
  border-radius: 24px;
}

.iconfeildauth {
  position: absolute;
  bottom: 10px;
  right: 13px;
  background-color: #fdfdff;
  width: 30px;
  text-align: end;
}

@media (max-width: 1920px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 270px;
    top: 40px;
  }
}

@media (max-width: 1820px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 220px;
    top: 40px;
  }
}

@media (max-width: 1720px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 160px;
    top: 40px;
  }
}

@media (max-width: 1620px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 100px;
    top: 40px;
  }
}

@media (max-width: 1520px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 80px;
    top: 40px;
  }
}

@media (max-width: 1420px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 20px;
    top: 40px;
  }
}

@media (max-width: 325px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 18px;
    top: 40px;
  }
}

@media (max-width: 325px) {
  .profile-modal .modal-dialog {
    position: fixed;
    right: 16px;
    top: 40px;
  }
}

.add-new-modal .modal-dialog .modal-content {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
          box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
  max-width: 372px;
  height: 465px;
  width: 100%;
  padding-left: 21px;
  padding-right: 21px;
}

.add-new-modal .modal-dialog .modal-content .modal-header {
  border: none;
}

.add-new-modal .modal-dialog .modal-content h4 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.add-new-modal .modal-dialog .modal-content .close-div button {
  background-color: transparent;
  background-color: #f9fafa;
  height: 43px;
  text-align: center;
  border-radius: 13px;
  border: none;
}

.add-new-modal .modal-dialog .modal-content .modal-body textarea {
  height: 128px;
  max-width: 387px;
  resize: none;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-new-modal .modal-dialog .modal-content .modal-body textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-color: #dbbb51;
}

.add-new-modal .modal-dialog .modal-content .modal-body textarea::-webkit-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-new-modal .modal-dialog .modal-content .modal-body textarea:-ms-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-new-modal .modal-dialog .modal-content .modal-body textarea::-ms-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-new-modal .modal-dialog .modal-content .modal-body textarea::placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-new-modal .modal-dialog .modal-content .submit-div button {
  display: block;
  background: #000000;
  border-radius: 20px;
  margin-left: auto;
  margin-top: 20px;
  max-width: 160px;
  height: 48px;
  width: 100%;
  border: none;
  color: #dbbb51;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  -webkit-transition: .1s ease-in;
  transition: .1s ease-in;
}

.add-new-modal .modal-dialog .modal-content .submit-div button:hover {
  background: #dbbb51;
  color: #ffffff;
}

@media (max-width: 1400px) {
  .patient-section canvas {
    height: 300px !important;
    width: 100% !important;
    margin: auto;
  }
  .dashboard-sidebar {
    padding-left: 10px;
  }
  .dashboard-sidebar .logo-container {
    padding-top: 45px;
    padding-bottom: 30px;
  }
  .dashboard-sidebar .logo-container img {
    width: 160px;
  }
}

@media (max-width: 1200px) {
  .dashboard-sidebar {
    padding-left: 10px;
  }
  .dashboard-sidebar .logo-container {
    padding-top: 45px;
    padding-bottom: 30px;
  }
  .dashboard-sidebar .logo-container img {
    width: 120px;
  }
  .dashboard-sidebar ul {
    text-decoration: none;
    list-style: none;
    padding-left: 0;
  }
  .dashboard-sidebar ul li {
    text-decoration: none;
    color: #899ca8;
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
  }
  .dashboard-sidebar ul li a {
    text-decoration: none;
    font-family: "Ageo";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #707581;
    line-height: 17px;
    cursor: pointer;
    min-height: 44px;
  }
  .dashboard-sidebar ul li a .nav-bg {
    padding-left: 5px;
  }
  .dashboard-sidebar ul li a .settings-icon {
    padding-right: 10px;
  }
  .dashboard-sidebar ul li a.active {
    color: #dbbb51;
    font-weight: 700;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
  }
  .dashboard-sidebar ul li a.active .nav-bg {
    background-color: #fbf8ee;
    width: 100%;
    margin-right: 5px;
    height: 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 20px;
    padding-left: 5px;
  }
  .dashboard-sidebar ul li a.active path {
    fill: #dbbb51;
  }
}

@media (max-width: 810px) {
  .donut-chart-container {
    padding: 20px;
    padding-left: 8px;
  }
}

@media (max-width: 768px) {
  .top-bar {
    display: block;
  }
  .donut-chart-container {
    padding: 30px;
    border-radius: 20px;
  }
}

@media (max-width: 576px) {
  .patient-section {
    padding: 10px 0;
  }
  .donut-chart-container {
    padding: 10px;
    border-radius: 20px;
  }
}

.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none !important;
}

.css-v68sna-control {
  border-radius: 20px !important;
}

.css-v68sna-control:focus {
  border-radius: 20px !important;
}

.add-category-modal .modal-dialog .modal-content {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
          box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
  min-height: 288px;
  width: 431px;
  padding-left: 21px;
  padding-right: 21px;
  margin: auto;
}

.add-category-modal .modal-dialog .modal-content .modal-header {
  border: none;
  margin-top: 16px;
}

.add-category-modal .modal-dialog .modal-content h4 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.add-category-modal .modal-dialog .modal-content .close-div button {
  background-color: transparent;
  background-color: #f9fafa;
  height: 43px;
  text-align: center;
  border-radius: 13px;
  border: none;
}

.add-category-modal .modal-dialog .modal-content .modal-body {
  padding-top: 0;
}

.add-category-modal .modal-dialog .modal-content .modal-body textarea {
  height: 128px;
  max-width: 387px;
  resize: none;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-category-modal .modal-dialog .modal-content .modal-body textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-color: #dbbb51;
}

.add-category-modal .modal-dialog .modal-content .modal-body textarea::-webkit-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-category-modal .modal-dialog .modal-content .modal-body textarea:-ms-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-category-modal .modal-dialog .modal-content .modal-body textarea::-ms-input-placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-category-modal .modal-dialog .modal-content .modal-body textarea::placeholder {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;
}

.add-category-modal .modal-dialog .modal-content .submit-div button {
  display: block;
  background: #000000;
  border-radius: 20px;
  margin-left: auto;
  margin-top: 20px;
  max-width: 160px;
  height: 40px;
  width: 100%;
  border: none;
  color: #dbbb51;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
  -webkit-transition: .1s ease-in;
  transition: .1s ease-in;
}

.add-category-modal .modal-dialog .modal-content .submit-div button:hover {
  background: #dbbb51;
  color: #ffffff;
}

.thumb-slider .slider {
  padding: 32px;
  color: #fff;
}

.thumb-slider .slider .swiper-container {
  width: 100%;
  height: 100%;
}

.thumb-slider .slider__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.thumb-slider .slider__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 150px;
  margin-right: 32px;
}

.thumb-slider .slider__prev,
.thumb-slider .slider__next {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.thumb-slider .slider__prev:focus,
.thumb-slider .slider__next:focus {
  outline: none;
}

.thumb-slider .slider__thumbs {
  height: calc(400px - 96px);
}

.thumb-slider .slider__thumbs .slider__image {
  -webkit-transition: 0.25s;
  transition: 0.25s;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}

.thumb-slider .slider__thumbs .slider__image:hover {
  opacity: 1;
}

.thumb-slider .slider__thumbs .swiper-slide-thumb-active .slider__image {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.thumb-slider .slider__images {
  height: 400px;
}

.thumb-slider .slider__images .slider__image img {
  -webkit-transition: 3s;
  transition: 3s;
}

.thumb-slider .slider__images .slider__image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.thumb-slider .slider__image {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}

.thumb-slider .slider__image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 767.98px) {
  .thumb-slider .slider__flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .thumb-slider .slider__col {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 0;
    margin-top: 24px;
    width: 100%;
  }
  .thumb-slider .slider__images {
    width: 100%;
  }
  .thumb-slider .slider__thumbs {
    height: 100px;
    width: calc(100% - 96px);
    margin: 0 16px;
  }
  .thumb-slider .slider__prev,
  .thumb-slider .slider__next {
    height: auto;
    width: 32px;
  }
}

.add-to-cart-modal .modal-dialog .modal-content {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
          box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
  max-width: 570px;
  height: 667px;
  min-width: 570px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-header {
  border: none;
}

.add-to-cart-modal .modal-dialog .modal-content h4 {
  margin-bottom: 0;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.add-to-cart-modal .modal-dialog .modal-content .close-div button {
  background-color: transparent;
  background-color: #f9fafa;
  height: 43px;
  text-align: center;
  border-radius: 13px;
  border: none;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart hr {
  background: #e3e3f1;
  height: 1px;
  width: 100%;
  margin: 0;
  margin-bottom: 24px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section {
  padding: 0 24px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .productimg-container {
  /* Product Image */
  width: 94px;
  height: 94px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .productimg-container img {
  border-radius: 16px;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .product-title {
  /* Black Minimalist Wall Clock */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: black;
  margin-bottom: 17px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .product-price {
  /* $120.20 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #000000;
  margin-bottom: 30px;
  text-align: right;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .delete-text {
  /* Delete */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f76356;
  padding-left: 8px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .also-recommend {
  /* Is also recommended */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 20px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .product-card-container {
  width: 170px;
  height: 170px;
  border-radius: 16px;
  position: relative;
  margin: 0 auto;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .product-card-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 16px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .product-card-container .card-title {
  /* Flower clock */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 8px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .product-card-container .white-circle {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .cancel-div button {
  display: block;
  background: #fdfdff;
  border-radius: 20px;
  margin-left: auto;
  margin-top: 15px;
  max-width: 100%;
  height: 48px;
  width: 100%;
  border: none;
  color: #000000;
  border: 1px solid #000000;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  margin-bottom: 20px;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .cancel-div button:hover {
  background: #dbbb51;
  color: #ffffff;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .submit-div button {
  display: block;
  margin-top: 50px;
  background: #000000;
  border-radius: 20px;
  margin-left: auto;
  max-width: 100%;
  height: 48px;
  width: 100%;
  border: none;
  color: #dbbb51;
  border: 1px solid #000000;
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}

.add-to-cart-modal .modal-dialog .modal-content .modal-body .add-to-cart .content-section .submit-div button:hover {
  background: #dbbb51;
  color: #ffffff;
  border: 1px solid #dbbb51;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  display: none;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  display: none !important;
}

.MuiDataGrid-footerContainer.css-1yy0dv2 {
  display: none;
}

.pagination-border {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.text-red-two {
  color: red;
  position: absolute;
  top: 47px;
  font-size: 11px;
  left: 9px;
}

.dark-golden-legend {
  height: 18px;
  width: 18px;
  background: #dbbb51;
}

.light-golden-legend {
  height: 18px;
  width: 18px;
  background: #faeec6;
}

.legend-text {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #707581;
  padding-left: 20px;
}

.donut-chart-container canvas {
  width: 170px !important;
  margin: auto;
  height: 170px !important;
  margin-top: 50px;
}

.mt_40 {
  margin-top: 40px;
}

.main-buyer-contact input.PhoneInputInput {
  background: transparent;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: rgba(219, 187, 81, 0.08) !important;
}

.show-all {
  /* Show all */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #77778d;
  cursor: pointer;
}

input#floatingInput {
  margin-top: 4px;
}

select#floatingSelect {
  margin-top: 4px;
}

.PhoneInput {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 4px;
}

.signup-form .form-floating select {
  width: 100%;
  height: 43px;
  border: 1px solid #ceced4;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
  padding: 13px 16px !important;
  resize: none;
}

.cursor-pointer {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.dashboard-dropdown .css-qbdosj-Input {
  width: 100px;
}

.dashboard-dropdown .css-b62m3t-container {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 120px !important;
  height: 40px !important;
}

.month-dropdown .css-qbdosj-Input {
  width: 100px;
}

.month-dropdown .css-b62m3t-container {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 140px !important;
  height: 40px !important;
}

.dashboard-main-heading {
  /* Dashboard */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #121212;
  margin-bottom: 20px;
  margin-top: 40px;
}

.start-date {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

@media (max-width: 1400px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;
  }
  .messages-section textarea {
    border: 1px solid #ceced4;
    border-radius: 20px;
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ceced4;
    width: 520px;
    min-height: 43px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #707581;
    margin: 0 10px;
    resize: none;
  }
  .messages-section textarea:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #707581;
    padding-top: 18px !important;
    padding-left: 10px !important;
  }
  .messages-section textarea:focus ::-webkit-input-placeholder {
    display: none;
  }
  .messages-section textarea:focus :-ms-input-placeholder {
    display: none;
  }
  .messages-section textarea:focus ::-ms-input-placeholder {
    display: none;
  }
  .messages-section textarea:focus ::placeholder {
    display: none;
  }
  .messages-section textarea::-webkit-scrollbar {
    display: none;
  }
  .messages-section textarea::-webkit-input-placeholder {
    padding-top: 18px !important;
    padding-left: 10px !important;
    position: absolute;
  }
  .messages-section textarea:-ms-input-placeholder {
    padding-top: 18px !important;
    padding-left: 10px !important;
    position: absolute;
  }
  .messages-section textarea::-ms-input-placeholder {
    padding-top: 18px !important;
    padding-left: 10px !important;
    position: absolute;
  }
  .messages-section textarea::placeholder {
    padding-top: 18px !important;
    padding-left: 10px !important;
    position: absolute;
  }
  .messages-section textarea:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .messages-section textarea:focus:-ms-input-placeholder {
    color: transparent;
  }
  .messages-section textarea:focus::-ms-input-placeholder {
    color: transparent;
  }
  .messages-section textarea:focus::placeholder {
    color: transparent;
  }
}

@media (max-width: 1250px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;
  }
  .messages-section textarea {
    border: 1px solid #ceced4;
    border-radius: 20px;
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ceced4;
    width: 380px;
    min-height: 43px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #707581;
    margin: 0 10px;
    resize: none;
  }
}

@media (max-width: 850px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;
  }
  .messages-section textarea {
    border: 1px solid #ceced4;
    border-radius: 20px;
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ceced4;
    width: 250px;
    min-height: 43px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #707581;
    margin: 0 10px;
    resize: none;
  }
}

@media (max-width: 400px) {
  .messages-section {
    background: #ffffff;
    border-radius: 14px;
    height: 647px;
    padding: 30px 20px;
    position: relative;
  }
  .messages-section textarea {
    border: 1px solid #ceced4;
    border-radius: 20px;
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ceced4;
    width: 150px;
    min-height: 43px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #707581;
    margin: 0 10px;
    resize: none;
  }
}

@media (max-width: 500px) {
  .bg-salmon {
    height: 180px;
  }
  .bg-salmon .product-desc {
    font-size: 14px !important;
  }
}

.css-cc8tf1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Ageo" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #979797 !important;
}

.css-cc8tf1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Ageo" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #979797 !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.order-detail-table-text {
  font-family: "Ageo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.order-est-delivery-time {
  /* (Est. delivery 15-17 Oct) */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0038em;
  color: #707581;
  margin-top: 10px;
}

@media (max-width: 450px) {
  .shop-container {
    height: 414px;
    border-radius: 24px;
    background-image: url("/src/assets/images/Home/Product ADS.png");
    background-size: cover;
  }
  .shop-container .product-details {
    padding-top: 110px;
    padding-left: 12px;
  }
}

@media (max-width: 576px) {
  .filter-btn-offcanvas .apply-btn {
    margin-left: 0;
  }
  .table-search-box input {
    background: #fbfbfd;
    border: none;
    width: 190px !important;
  }
}

@media (max-width: 410px) {
  .product-bg .product-data .made-in-section {
    width: 100%;
  }
}

.details-description-width {
  width: 50% !important;
}

@media (max-width: 1200px) {
  .details-description-width {
    width: 100% !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3a3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbbb51;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #dbbb51 !important;
}

.datagrid-table::-webkit-scrollbar {
  display: none;
}

.swiper-testing .mySwiper {
  height: 100%;
  width: 100%;
  padding-left: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0;
  background: white !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.swiper-testing .mySwiper img {
  width: 57px;
  height: 57px;
  border-radius: 8px;
}

.afrorierre__control.css-yq64p-control {
  height: 40px !important;
}

.form-select {
  background-image: url("../images/select-down-arrow.png");
  background-size: auto;
  cursor: pointer;
}

.bg-salmon {
  min-width: 100%;
  background: #f6f6fa;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-top: 20px;
}

.bg-salmon .img-container {
  position: unset;
}

.bg-salmon .top-right {
  position: absolute;
  bottom: 8px;
  right: 65px;
}

.bg-salmon .bottom-right {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.bg-salmon .bottom-left {
  position: absolute;
  bottom: 8px;
  right: 120px;
}

.bg-salmon .price-tag {
  width: 105px;
  height: 40px;
  background: #fdfdff;
  border: 1px solid #dbbb51;
  border-radius: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.bg-salmon .price-tag .price {
  /* $122 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.bg-salmon .price-tag strike {
  color: red;
}

.bg-salmon .price-tag .original-price {
  /* $154 */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8888a2 !important;
  margin-left: 8px;
}

.bg-salmon .icon-container {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fdfdff;
  border-radius: 24px;
  cursor: pointer;
}

.bg-salmon .product-desc {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #000000;
  margin-top: 12px;
  padding: 0 12px;
  padding-bottom: 10px;
}

.list-grid-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list-grid-view .form-check-input[type="radio"] {
  border-radius: 50%;
  display: none;
}

.list-grid-view .form-check-input[type="radio"]:has(input:checked) label {
  border: 1px solid #000000;
  background: red;
}

.bg-yellow {
  background: #dbbb51 !important;
}

.toast-notif .main-toast-heading {
  /* Congratulations! */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 5px;
}

.toast-notif .toast-subheading {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5f5f5f;
}

.toast-notif .pl_15 {
  padding-left: 15px;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
  min-width: 430px;
}

.seller-paginations .page-box {
  border-radius: 8px;
  width: 30px;
  height: 30px;
  background: #fbfbfd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* PN0001265 */
  font-family: "Ageo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #707581;
  margin-left: 9px;
  cursor: pointer;
}

.seller-paginations .page-box.active {
  background: #dbbb51;
  color: #fdfdff;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  font-family: "Ageo", sans-serif;
  color: #979797;
  font-size: 12px;
}

.delete-acc-dropdown .css-qbdosj-Input {
  width: 100%;
}

.delete-acc-dropdown .css-b62m3t-container {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100% !important;
  height: 43px !important;
}

.all-profile-heading {
  /* Dashboard */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 22px;
  margin-top: 5px;
}

.edit-profile-heading {
  /* The Half Moon */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 5px;
}

.edit-profile-both-sides {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.edit-profile-both-sides .edit-profile-left-side-text {
  /* UI/UX Designer */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #3a3b3b;
  width: 150px;
}

.edit-profile-both-sides .edit-profile-right-side-text {
  /* UI/UX Designer */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5c5c5c;
}

.edit-profile-btn {
  width: 160px;
  height: 40px;
  color: #dbbb51;
  background: #000000;
  /* Button */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-radius: 20px;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #000000;
}

.edit-profile-btn:focus, .edit-profile-btn:hover {
  background: #000000 !important;
  color: #dbbb51;
  border: 1px solid black;
}

.size-chart-text {
  /* Upload Images */
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #707581;
  margin-top: 30px;
}

.add-product-modal {
  width: 160px;
  height: 40px;
  background: #000000;
  font-family: #dbbb51;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #dbbb51;
  border-radius: 40px;
  margin-top: 20px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  outline: none;
}

.add-product-modal:focus, .add-product-modal:hover {
  background: #000000;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
  border: none !important;
  color: #dbbb51 !important;
}
