.main-setting-menu {
  margin-top: 30px;
  background: #f6f6fa;
  border-radius: 16px;
  padding: 12px;

  h2 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: $black;
    margin-bottom: 0;

  }

  .setting-tab {
    a {
      display: flex;
      align-items: center;
      line-height: 16.8px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 8px;
      height: 40px;
      padding-left: 8px;
      .svg-div {
        width: 24px;
        height: 24px;
      }
      .side-menu-link {
        h4 {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $black;
          margin-bottom: 0;
          padding-left: 8px;
        }
      }
      &.active {
        border: 1.5px solid $base-yellow;
        rect {
          fill: $base-yellow;
        }
        path {
          fill: $black;
        }
      }
    }
  }

  .logout-profile{
    cursor:pointer;
    display: flex;
    align-items: center;
    line-height: 16.8px;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 8px;
    height: 40px;
    padding-left: 8px;
    .svg-div {
      width: 24px;
      height: 24px;
    }
    .side-menu-link {
      h4 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $black;
        margin-bottom: 0;
        padding-left: 8px;
      }
    }
    &.active {
      border: 1.5px solid $base-yellow;
      rect {
        fill: $base-yellow;
      }
      path {
        fill: $black;
      }
    }
  }
}
