.buyer-product-details {
  .side-border {
    border-left: 1px solid $border-gray;
    padding-left: 30px;

    .buyer-product-title {
      /* Black Minimalist Wall Clock */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: $black;
    }

    .size {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $size-color;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .sizes-btns {
      .form-check {
        padding: 0;
        margin-right: 15px;
      }
      .form-check-input {
        display: none;
      }
      form {
        display: flex;
        align-items: center;
      }
      label {
        height: 40px;
        width: 40px;
        border: 1px solid $border-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
      }
      input[type="radio"]:checked + label {
        border: 1px solid $base-yellow;
        background: transparent;
      }
    }

    .color-btns {
      .form-check {
        padding: 0;
        margin-right: 15px;
      }
      .form-check-input {
        display: none;
      }
      form {
        display: flex;
        align-items: center;
      }
      label {
        border: 1px solid $border-gray;
        height: 27px;
        width: 27px;
        border-radius: 20px;
      }
      input[type="radio"]:checked + label {
        border: 1px solid $black;
        background: transparent;
        height: 27px;
        width: 27px;
        border-radius: 20px;
      }
    }

    .color-btn {
      width: 25px;
      height: 25px;
      border-radius: 20px;
    }

    // stock btn section starts here
    .stock-btn {
      width: 111px;
      height: 40px;
      border-radius: 8px;
      background: $stock-btn-bg;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $stock-btn-text;
      border: 1px solid $stock-btn-text;
      display: flex;
      align-items:center;
      justify-content: space-between;
    }
    // stock btn section ends here

    // wishlist btn css start
    .wishlist-btn {
      /* Wishlist */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $black;
      background: $white-color;
      border: 1px solid $white-color;
      display: flex;
      align-items: center;

      .wishlist-icon-container {
        background: $border-gray;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
      }

      span {
        padding-left: 10px;
      }
    }

    .added {
      path {
        fill: $base-yellow;
      }
    }
    // wishlist btn css ends

    // Made in section css start
    .made-in-section {
      width: 343px;
      height: 60px;
      background: #f6f6fa;
      border-radius: 10px;
      margin-top: 15px;
      padding: 0 10px;
      .heading {
        /* Made In */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #a7a7a8;
      }

      .seperator-line {
        height: 40px;
        border-left: 0.5px solid #ceced4;
      }

      .no-of-orders {
        /* 360 */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
      }
    }

    .buyer-product-rating {
      /* 4.0 (258) */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $black;
    }

    .reviews-num {
      color: #707581;
      padding-left: 5px;
    }

    // Made in section css end
  }

  // Quantity in css starts
  .quantity-section {
    margin-top: 20px;

    .quantity-heading {
      /* Quantity: */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $black;
      margin-bottom: 12px;
    }

    .quantity-btn {
      width: 103px;
      height: 48px;
      border: 1px solid $black;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      user-select: none!important;
    }

    .add-to-cart-btn {
      background: $black;
      color: $base-yellow;
      border: 1px solid $black;
      height: 48px;
      width: 220px;
      border-radius: 100px;
      &:hover,
      &:focus {
      }
    }
  }
  // Quantity in css ends

  // product accordion css start
  .product-details-accordion {
    .accordion {
      background: #f6f6fa;
      border-radius: 24px;
    }
    .accordion-button {
      background: transparent;
      &:focus {
        box-shadow: none;
        color: $black !important;
      }
    }
    .accordion-button:not(.collapsed) {
      color: $black !important;
      ::after {
        background-image: none !important;
      }
    }
    .accordion-item {
      background: transparent !important;
      border: none !important;
      border-radius: 30px;
    }

    .acc-heading {
      /* Shipping */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $primary-fonts;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-icon) !important;
      transform: var(--bs-accordion-btn-icon-transform);
    }

    .conditions {
      /* Shipping Charges: $10 worldwide */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #707581;
      padding-right: 5px;
    }

    hr {
      /* Separator */
      margin: 0;
      margin-top: 24px;
      height: 1px;
      background: #e3e3f1;
    }
  }
  // product accordion css end

  // msg-seller-css-start-here
  .message-seller {
    background: $navbar-bg;
    padding: 14px 22px;
    border-radius: 24px;
    margin-top: 30px;

    .halfmoon-title {
      /* The Half Moon */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $size-color;

      span {
        color: black;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .seller-btn {
      width: 100%;
      height: 40px;
      background: transparent;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      color: $black;
      border: 1px solid $black;
      border-radius: 20px;
      margin-top: 20px;
    }
  }
  // msg-seller-css-end-here

  // tabs css starts here
  .tab-btn-container {
    background: $navbar-bg;
    padding: 12px;
    border-radius: 16px;

    a {
      /* Description */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      height: 33px;
      margin-top: 8px;
      background: white;
      color: $black;
      border-radius: 8px;
      &.active {
        background: $tab-btn-active;
        color: $black;
      }
    }
  }
  // tabs css ends here

  // tab section heading start
  .tabs-heading {
    /* Description */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .tabs-para {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  // tab section heading end

  // mroe product section

  .more-product-section {
    margin: 30px 0 70px 0;

    .more-heading {
      /* More from this shop */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-bottom: 16px;
    }

    .see-more {
      /* See more */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 29px;
      color: $black;
      margin-bottom: 16px;
      cursor: pointer;
    }
  }
}
