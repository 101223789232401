.buyer-product-listing {
  margin-top: 30px;
  margin-bottom: 70px;
  .seller-profile-card {
    min-height: 198px;
    background: $navbar-bg;
    padding: 25px;
    border-radius: 24px;

    .seller-img-container {
      border-radius: 75px;
      height: 142px;
      width: 142px;
      object-fit: contain;
    }

    .seller-profile-title {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 18px;
      color: $black;
    }

    .seller-location {
      /* Houston, Texas, US */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #707581;
      margin-top: 15px;
      margin-bottom: 20px;
    }
    .no-of-product-sold {
      /* 5,652 product sold */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #7c7c7e;
      border-right: 1px solid $gray-line;
      width: 140px;
      padding-right: 15px;
    }

    .star-reviews {
      /* 4.0 (258) */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      margin-top: 8px;
      margin-left: 3px;

      span {
        color: $colorgray;
      }
    }

    .data-section {
      margin-left: 34px;
    }

    .msg-seller-btn {
      background: transparent;
      border: 1px solid $black;
      color: $black;
      width: 175px;
      height: 40px;
      border-radius: 20px;

      font-family: "Ageo";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-transform: capitalize;

      /* Black */
      color: $black;
    }
  }

  .all-products-heading {
    /* All Products */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: $black;
    margin-top: 30px;
    margin-bottom: 16px;
  }

    // tabs css starts here
    .tab-btn-container {
        background: $navbar-bg;
        padding: 12px;
        border-radius: 16px;
    
        a {
          /* Description */
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $black;
          height: 33px;
          margin-top: 8px;
          background: white;
          color: $black;
          border-radius: 8px;
          &.active {
            background: $tab-btn-active;
            color: $black;
          }
        }
      }
      // tabs css ends here
    
      // tab section heading start
      .tabs-heading {
        /* Description */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
      .tabs-para {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
      // tab section heading end
}
