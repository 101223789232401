.orderno-bg {
  background: white;
  width: 100%;
  height: 356px;
  border-radius: 14px;
  padding: 20px;

  h2 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #121212;
    margin-bottom: 5px;
  }

  .order-no {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $base-yellow;
  }

  .order-data {
    margin-top: 15px;
    .order-ques {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $colorgray;
    }
    .order-ans {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $black;
    }
  }
}

.order-detail-table {
  background: #ffffff;
  border-radius: 14px;
  height: 506px;
  padding: 20px;
}

.reject-btn {
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  /* Black */
  color: $black;
  background: transparent;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid $black;
  margin-top: 20px;
  &:hover,
  &:focus {
    background: $base-yellow !important;
    color: $black !important;
    border: 1px solid $base-yellow !important;
  }
}

.accept-btn {
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  /* Black */
  color: $base-yellow;
  background: $black;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid $black;
  margin-left: 20px;
  margin-top: 20px;

  &:hover,
  &:focus {
    background: $base-yellow !important;
    color: $black !important;
    border: 1px solid $base-yellow !important;
  }
}

.table-search-box {
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  input {
    background: #fbfbfd;
    border: none;
    width: 334px !important;
    height: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid white !important;
    border-left: none;

    /* Search */

    font-family: "Ageo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #7d8592;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      background: #fbfbfd;
    }
  }

  span#basic-addon1 {
    background: #fbfbfd;
    border: 1px solid white;
    border-right: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 15px;
    cursor: pointer;
    :hover {
      path {
        fill: $base-yellow;
      }
    }
  }
}

.table-right-btn {
  width: 160px;
  height: 40px;
  background: $black;
  color: $base-yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Button */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-radius: 20px;
  border: 1px solid $black;
  margin-left: 10px;

  &:focus,
  &:hover {
    box-shadow: none;
    border: 1px solid $black;
    background: $black !important;
    color: $base-yellow;
  }
}

.order-dropdown {
  .css-qbdosj-Input {
    width: 100px;
  }
  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 160px!important;
    height: 40px!important;
}
}

.category-parent-dropdown {
  margin: 20px 0;
  .css-qbdosj-Input {
    height: 33px;
    font-family: $primary-fonts!important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #707581!important;
  }

  .parent-opt{
    /* Parent */
font-family: $primary-fonts;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 14px;
color: #707581;
  }

  .sub-parent{
    // padding-left: 20px;
  }
  
  
}



.category-img-div {
  position: relative;
  width: 89px;
  height: 89px;
  border-radius: 14px;
  margin-bottom: 24px;

  img {
    border-radius: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
  }

  .file-upload {
    width: 90px;
    height: 90px;
    border-radius: 14px;
    overflow: hidden;
  }
}



.table-content-center{
  display: flex;
  flex-direction: column;
  .css-17jjc08-MuiDataGrid-footerContainer{
    display: none!important;
  }
  .MuiDataGrid-footerContainer.css-1yy0dv2 {
    display: none!important;
}
.MuiDataGrid-footerContainer{
  display: none!important;
}
}