@import "../fonts/Ageo/stylesheet.css";
@import "./variable";
@import "./Navbar";
@import "./Footer";
@import "../css/components/ProductCard";
@import "../css/components/ShopNowSlider";
@import "../css/components/Swiper";
@import "../css//Auth";
@import "../css//components/Sidebar";
@import "../css/Dashboard";
@import "../css/components/TopBar";
@import "../css/components/ProductSlider";
@import "../css/ProductDetails";
@import "../css/components/RatingandReview";
@import "../css/Order";
@import "../css/components/Chat";
@import "../css/Settings";
@import "../css/MyProfile";
@import "../css/components//Modals/MyProfile";
@import "../css/Search";
@import "../css/Product";
@import "../css/AddProduct";
@import "../css/components/Modals/NotificationModal";
@import "../css/components/Modals/DeleteModal";
@import "../css/components/Modals/EditProductModal";
@import "../css/components/Modals/ReportChat";
@import "../css/Home";
@import "../css/ProductListing";
@import "../css/BuyerProductDetail";
@import "../css/BuyerProductListing";
@import "../css/Cart";
@import "../css/Checkout";
@import "../css/WIshlist";
@import "../css/SelectRole";
@import "../css/components/BuyerProfileSideMenu";
@import "../css/BuyerProfile";
@import "../css/components/Modals/CancelOrderModal";
@import "../css/components/Modals/ReviewModal";
@import "../css/Responsive";
@import "../css/components/Modals/AddNewModal";
@import "../css/Dashboardresponsiveness";
@import "../css/components/Modals/AddCategoryModal";
@import "../css/ThumbSlider";
@import "../css/components/Modals/AddtoCartModal";
@import "../css/BugFix";

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $base-gray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $base-yellow;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: $base-yellow !important;
}

// .scroll-div{
//   overflow: scroll;
//   overflow-x: hidden!important;
//   height: calc(100vh - 120px);
//   &::-webkit-scrollbar {
//     display: none;
//   }

// }

.datagrid-table {
  &::-webkit-scrollbar {
    display: none;
  }
}

.swiper-testing {
  .mySwiper {
    height: 100%;
    width: 100%;
    padding-left: 24px;
    box-sizing: border-box;
    padding: 10px 0;
    background: white !important;
    box-shadow: none !important;
    img {
      width: 57px;
      height: 57px;
      border-radius: 8px;
    }
  }
}

.afrorierre__control.css-yq64p-control {
  height: 40px !important;
}

.form-select {
  background-image: url("../images/select-down-arrow.png");
  background-size: auto;
  cursor: pointer;
}

.bg-salmon {
  min-width: 100%;
  background: #f6f6fa;
  border-radius: 16px;
  display: flex;
  position: relative;
  margin-top: 20px;

  .img-container {
    position: unset;
  }

  .top-right {
    position: absolute;
    bottom: 8px;
    right: 65px;
  }
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
  .bottom-left {
    position: absolute;
    bottom: 8px;
    right: 120px;
  }

  .price-tag {
    width: 105px;
    height: 40px;
    background: $white-color;
    border: 1px solid $base-yellow;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .price {
      /* $122 */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black;
    }

    strike {
      color: red;
    }

    .original-price {
      /* $154 */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #8888a2 !important;
      margin-left: 8px;
    }
  }

  .icon-container {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white-color;
    border-radius: 24px;
    cursor: pointer;
  }

  .product-desc {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: $black;
    margin-top: 12px;
    padding: 0 12px;
    padding-bottom: 10px;
  }
}

.list-grid-view {
  display: flex;
  .form-check-input[type="radio"] {
    border-radius: 50%;
    display: none;
    &:has(input:checked) label {
      border: 1px solid $black;
      background: red;
    }
  }
}

.bg-yellow {
  background: $base-yellow !important;
}

// Seller QA Fixes

.toast-notif {
  .main-toast-heading {
    /* Congratulations! */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $black;
    margin-bottom: 5px;
  }
  .toast-subheading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5f5f5f;
  }
  .pl_15 {
    padding-left: 15px;
  }
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
  min-width: 430px;
}

.seller-paginations {
  .page-box {
    border-radius: 8px;
    width: 30px;
    height: 30px;
    background: #fbfbfd;
    display: flex;
    align-items: center;
    justify-content: center;
    /* PN0001265 */
    font-family: "Ageo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #707581;
    margin-left: 9px;
    cursor: pointer;
    &.active {
      background: $base-yellow;
      color: $white-color;
    }
  }
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  font-family: $primary-fonts;
  color: #979797;
  font-size: 12px;
}

.delete-acc-dropdown {
  .css-qbdosj-Input {
    width: 100%;
  }
  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100% !important;
    height: 43px !important;
  }
}

.all-profile-heading {
  /* Dashboard */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: $black;
  margin-bottom: 22px;
  margin-top: 5px;
}

.edit-profile-heading {
  /* The Half Moon */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: $black;
  margin-top: 20px;
  margin-bottom: 5px;
}

.edit-profile-both-sides {
  margin-top: 10px;
  display: flex;
  align-items: center;
  .edit-profile-left-side-text {
    /* UI/UX Designer */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #3a3b3b;
    width: 150px;
  }

  .edit-profile-right-side-text {
    /* UI/UX Designer */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5c5c5c;
  }
}

.edit-profile-btn {
  width: 160px;
  height: 40px;
  color: $base-yellow;
  background: $black;
  /* Button */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-radius: 20px;
  outline: none;
  box-shadow: none;
  border: 1px solid $black;
  &:focus,
  &:hover {
    background: $black !important;
    color: $base-yellow;
    border: 1px solid black;
  }
}

.size-chart-text {
  /* Upload Images */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #707581;
  margin-top: 30px;
}

.add-product-modal {
  width: 160px;
  height: 40px;
  background: $black;
  font-family: $base-yellow;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: $base-yellow;
  border-radius: 40px;
  margin-top:20px;
  box-shadow: none;
  border:none;
  outline:none;
  &:focus,&:hover{
    background:$black;
    box-shadow: none!important;
    outline:none!important;
    border: none!important;
    color: $base-yellow!important;
  }
}
